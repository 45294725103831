import React, {  useState, useLayoutEffect} from "react";
import "./../../../assets/css/user/profile/profileHead.css";
import loadLogo from '../../../assets/images/global/sidebar-logo.png';
// import UserProfile from "./UserProfile";
import Select from "react-select";
import SubscriptionPopup from "../../../components/user/popup/subscriptionPopup";
// import { useParams } from "react-router-dom";
import apiClient from "../../apiClient";

const ProfileContent = ({item,setEditPopup,editPopup,setShowPopup,showPopup,setRightSidebarActive,setProfileActive}) => {
  // token,useremail,userimage,usercountry
  let token;
  const [apiCall, setAPICall] = useState(false);
  const [calendarSub, setCalendarSub] = useState(false);
  const [layoutLoad, setLayoutLoad] = useState(true);
  const [preview, setPreview] = useState(null);
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(true);
  const [userEmail, setUserEmail] = useState({});
  const [countryData, setCountryData] = useState({});
  const [professionData, setProfessionData] = useState({});
  const [errorText, setErrorText] = useState(false);
  let profileImage;    
  profileImage = localStorage.getItem("profileImg");
  const [uploadProfile, setUploadProfile] = useState({
    "firstname":'',
    "lastname": '',
    "email": localStorage.getItem("userEmail"),
    "profession":'',
    "jobtitle":'',
    "country":'',
    "countrycode": '',
    "city":'',
    "currency": localStorage.getItem("currencyCode"),
    "companyname":'',
    "phone": '',
  });
  const countryCode = [
    {dial_code: "+91"},
    {dial_code: "+92"},
    {dial_code: "+93"},
    {dial_code: "+94"},
    {dial_code: "+95"},
    {dial_code: "+96"},
  ]
    const [errors, setErrors] = useState({
      firstName: '',
      lastName: '',
      number: '',
    });

    const validateForm = () => {
      let tempErrors = {};
      if (!uploadProfile.firstname) {
        tempErrors.firstName = "First Name is required";
      }
      if (!uploadProfile.lastname) {
        tempErrors.lastName = "Last Name is required";
      }
      // if (!uploadProfile.phone.number || !uploadProfile.number) {
      //   tempErrors.number = "Mobile Number is required";
      // }
      setErrors(tempErrors);
      return Object.values(tempErrors).length === 0;
    };
  
    const handleInputs = (e) => {
      
      // Check if it's a custom event with name and value properties
      if (e.name && e.value) {
        const { name, value } = e;  
        setUploadProfile((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else if (e.target) {
        // Handle standard input event
        const { name, value } = e.target;
    
        setUploadProfile((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        console.warn("Unhandled event type:", e);
      }
    };
    
    const getUserProfile = async () => {
      token = localStorage.getItem("token");
      try {
        const response = await apiClient.get(
          "/api/profile",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Contro-Allow-Origin": "*",
            },
          }
        );
        const json = response.data;
        let jsonUserData = json.data;
        localStorage.setItem('userName', jsonUserData.firstname);
        localStorage.setItem("profileImg",jsonUserData.profile_pic);
        setProfileActive(true);
        setUploadProfile(jsonUserData);
      } catch (error) {
        console.error(error);
      }
    };
    const getCountry = () => {
      token = localStorage.getItem("token"); 
      setUserEmail(localStorage.getItem("userEmail")); 
      apiClient.get('/api/common', {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          const json = response.data;
          setCountryData(json.countries);
          setProfessionData(json.professions);
          setLayoutLoad(false);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the data:", error);
        // Handle the error accordingly
      });
    }
  useLayoutEffect(() => {    
    getUserProfile();
    getCountry();
  }, []);

  const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB
const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif']; // Example accepted file types

const handleImageChange = async (event) => {
  event.preventDefault();  
  setDisabled(true);
  const token = localStorage.getItem("token");
  const file = event.target.files[0];
  
  // Check if a file was selected
  if (!file) {
    setValidate(true);
    setError("No file selected");
    setTimeout(closePoup, 3000);
    console.error("No file selected");
    return;
  }

  // Check file size and type
  if (file.size > MAX_FILE_SIZE) {
    setValidate(true);
    setError("File size exceeds limit. Please upload below 1MB");
    setTimeout(closePoup, 3000);
    console.error("File size exceeds limit");
    return;
  }
  if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
    setValidate(true);
    setError("Invalid file type");
    setTimeout(closePoup, 3000);
    console.error("Invalid file type");
    return;
  }

  setPreview(URL.createObjectURL(file));

  // Create FormData object
  const formData = new FormData();
  formData.append('image', file);

  try {
    // Send request to server
    const response = await apiClient.post('/api/uploadImg', formData, {
      headers: {
        Authorization: `Bearer ${token}`,        
        "Accept": "application/json",
        "Content-Type": "multipart/form-data"
      },
      timeout: 5000 // optional timeout
    });

    const data = await response.data;
    localStorage.setItem('profileImg', data.url);
    setProfileActive(true); 
    setDisabled(false);
  } catch (error) {
    if (error.name === 'AbortError') {
      console.error("Request timeout:", error);
    } else {
      console.error("Error uploading image:", error);
    }
  }
};
  

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    token = localStorage.getItem("token");
    if (validateForm()) {
        setLayoutLoad(true);      
        localStorage.setItem('userName', uploadProfile.firstname);
        setProfileActive(true);
        await apiClient.put('/api/profile', uploadProfile, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept": "application/json",
          },
        })
        .then((response) => {
          const resData = response.data;
          if (response.status === 200) {
            setDisabled(true);
            setValidate(true);
            setError(resData.message);
            setTimeout(closePoup, 3000);
            getUserProfile();
            getCountry();
            // setUploadProfile(resData.data);
            setLayoutLoad(false);
            setErrorText(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            setValidate(true);
            setError(error.response.data.message);
            setTimeout(closePoup, 3000);
            setLayoutLoad(false);
            setErrorText(false);
          }
        });
    }
  }
  const handleEditProfile  = () => {  
    token = localStorage.getItem("token"); 
    // getUserProfile();
    getCountry();
    setDisabled(false)
  }
  const closePoup = () => {        
    setValidate(false);
  }
  return (
    <div className="profileMainContainer">
      {layoutLoad && 
        <div className="loadSection">
          <div className="loadLayout">                        
            <div className="loader"></div>
            <img src={loadLogo} alt="Smarty Pays Logo" className="loadLogo" />
          </div>
        </div>
      } 
      {!layoutLoad && 
        <div className="profileSection">
          <div className="profileLayout">
            <div className="profileContainer">
              {/* <form className="profileFormSection"> */}
                <div className="profileBannerSection">
                  <div className="profileBannerLayout">
                    <div className="profileImgSection">
                      <div className="profileImgLayout">
                        {preview || profileImage !== 'null' ? (
                          <img src={profileImage} alt="Preview" />
                            ) : ( 
                              <span className="material-icons defaultProfileImage">person</span>
                                )}
                                {!disabled &&
                                  <div className="uploadProfileImage">
                                    <span className="material-icons-outlined">create</span>
                                  </div>
                                }
                                  <input type="file" onChange={handleImageChange} name="image" />
                                  {disabled && <div className="disabledImage" title="Click Edit Profile"></div>}
                                  </div>                   
                    </div>
                    <div className="position-rel d-flex editButton">
                      <button className="btn-15 cursor-pointer" onClick={handleEditProfile} disabled={!disabled}>Edit Profile</button>
                    </div>
                  </div>
                </div>
              {/* </form> */}
              {validate && (
  <div className="errorMessageLayout" id='errorMessageLayout'>
    <div className='errorMessageSection'>
      <p className={`fs-18 fw-500 ${errorText ? 'successMessage' : 'errorMessage'}`}>{error}</p>
      <span className="material-icons-outlined closeIcon" onClick={closePoup}>close</span>
    </div>
  </div>
)}
              <div className="profileBodySection">
                <div className="profileBodyLayout noScrollBar">
                  <form className="userFormLayout" onSubmit={handleSubmit}>
                    <div className="userInfo">
                      <div className="titleSection">
                        <div className="profileIcon">                          
                          <span className="material-symbols-outlined user_icon">person</span>
                        </div>
                        <h5 className="userInformation">User Information</h5>
                      </div>
                      <div className="form-list">
                        <div className="form-group">
                          <label htmlFor="firstName">*First Name:</label>
                          <input
                            type="text"
                            name="firstname"
                            id="firstName"
                            className="form-control"
                            placeholder="First Name"
                            value={uploadProfile.firstname}
                            onChange={handleInputs}
                            disabled={disabled}
                          /> 
                          {errors.firstName && <p className='errorMessageValidate' >{errors.firstName}</p>}
                        </div>
                        <div className="form-group">
                          <label htmlFor="lastName">*Last Name:</label>
                          <input
                            type="text"
                            name="lastname"
                            id="lastName"
                            className="form-control"
                            placeholder="Last Name"
                            value={uploadProfile.lastname}
                            onChange={handleInputs}
                            disabled={disabled}
                          /> 
                          {errors.lastName && <p className='errorMessageValidate' >{errors.lastName}</p>}
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Email:</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="demo@gmail.com"
                            value={userEmail}
                            disabled={true}
                            onChange={handleInputs}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="number">*Phone:</label>
                          <input
                            type="number"
                            name="phone"
                            id="number"
                            className="form-control"
                            placeholder="000-000-00-00"
                            value={uploadProfile.phone ? uploadProfile.phone : '' }
                            onChange={handleInputs}
                            disabled={disabled}
                          /> 
                          {errors.number && <p className='errorMessageValidate' >{errors.number}</p>}
                        </div>
                        </div>
                    </div>
                    <div className="contactInfo">
                      <div className="titleSection">
                        <div className="profileIcon">                          
                          <span className="material-symbols-outlined user_icon">contact_phone</span>
                        </div>
                        <h5 className="userInformation">Contact Information</h5>
                      </div>
                      <div className="form-list" >
                        <div className="form-group">
                          <label htmlFor="profession">Profession</label>
                          {/* <input
                            type="text"
                            name="profession"
                            id="profession"
                            className="form-control"
                            placeholder="Accountant"
                            value={formData.profession}
                            onChange={handleInputs}
                            disabled={disabled} /> */}
                          <Select 
                            className="selectInput profession"
                            onChange={handleInputs}
                            options={professionData.map(e => ({ label: e.name, value: e.name, name:'profession'}))}
                            value={{ label: uploadProfile.profession, value: uploadProfile.profession, name:"profession" }}
                            isDisabled={disabled}
                            />
                        </div>
                        <div className="form-group">
                          <label htmlFor="countryCode">Country</label>
                          <Select 
                            className="selectInput"
                            onChange={handleInputs}
                            options={countryData.map(e => ({ label: e.name, value: e.name, name:'country'}))}
                            value={{ label: uploadProfile.country, value: uploadProfile.country, name:"country" }}
                            isDisabled={disabled}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="countryCode">CountryCode</label>
                          <Select 
                            className="selectInput"
                            onChange={handleInputs}
                            options={countryCode.map(e => ({ label: e.dial_code, value: e.dial_code, name: 'countrycode'}))}
                            value={{ label: uploadProfile.countrycode ? uploadProfile.countrycode : 'Select...', value:  uploadProfile.countrycode ? uploadProfile.countrycode : '', name:"countrycode" }}
                            isDisabled={disabled}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="city">City</label>
                          <input type="text" name="city" value={uploadProfile.city} className="city" id="city" disabled={disabled} onChange={handleInputs} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="country">Company Name</label>
                          <input
                            type="text"
                            name="companyname"
                            id="companyName"
                            className="form-control"
                            placeholder="CompanyName"
                            value={uploadProfile.companyname}
                            onChange={handleInputs}
                            disabled={disabled} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="phone">Job Title</label>
                          <input
                            type="text"
                            name="jobtitle"
                            id="jobTitle"
                            className="form-control"
                            placeholder="Developer"
                            value={uploadProfile.jobtitle}
                            onChange={handleInputs}
                            disabled={disabled} />
                        </div>
                      </div>
                    </div>
                    
                    <div className="buttonLaout">               
                      <div className="position-fixed d-flex pb-10">
                        <button type="type" className="profileBtn cursor-pointer" disabled={disabled}>Save</button>
                      </div>
                    </div>   
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {showPopup && 
        <div className="subscriptionPopupLayout">
          <SubscriptionPopup setShowPopup = {setShowPopup} setAPICall={setAPICall} apiCall={apiCall} calendarSub = {calendarSub} setCalendarSub={setCalendarSub} item={item} editPopup={editPopup} setEditPopup={setEditPopup} setRightSidebarActive = {setRightSidebarActive} />
        </div>
      }
    </div>  
  );
};


export default ProfileContent;