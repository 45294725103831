import "../../../assets/css/user/sidebar/rightSidebar.css";
import loadLogo from '../../../assets/images/global/sidebar-logo.png';
import { useEffect, useState, useLayoutEffect } from 'react';
import apiClient from "../../../pages/apiClient";
const RightSidebar = ({rightSidebarActive,setRightSidebarActive,setItem, setEditPopup, setShowPopup}) =>{
    const [layoutLoad, setLayoutLoad] = useState(true);
    const [upcomingDataList, setUpcomingDataList] = useState({});
    const [budgetData, setBudgetData] = useState();
    const token = localStorage.getItem('token');
    const currencySymbol = localStorage.getItem('currencyCode');
    const closeRightSidebar = () => {
        if(rightSidebarActive){
            setRightSidebarActive(false);
        }else{
            setRightSidebarActive(true);
        }        
    }
    const handleDateFormate = (dateEvent) => {
        if(dateEvent !== '' && dateEvent !== null && dateEvent !== 'undefined'){            
            let removeTime = dateEvent.split('T')[0];
            let resultDate = new Date(removeTime).toLocaleString('en-us',{month:'short', day:'numeric', year:'numeric'});
            return resultDate;            
        }else{
            return " - "
        }
        
        
    }
    const dashboardAPI = async () => {
        try {
            const response = await apiClient.get("/api/entries", {
                headers: { "Authorization": `Bearer ${token}` }
            });
            const json = await response.data;
            if (json) {
                if (json.upcoming_payments) {
                    setUpcomingDataList(json.upcoming_payments);
                }
                if (json.expected_expense) {
                    setBudgetData(json.expected_expense);
                }
            }
        } catch (error) {
            // Handle error
            console.error("Error fetching dashboard data:", error);
        } finally {
            setLayoutLoad(false);
        }
    };
    useLayoutEffect(() => {
        setLayoutLoad(true);
    },[]);
    useEffect(() => {
        dashboardAPI();
    },[])
    return(
        <>
        {layoutLoad && 
            <div className="loadSection">
                <div className="loadLayout">                        
                    <div className="loader"></div>
                    <img src={loadLogo} alt="Smarty Pays Logo" className="loadLogo" />
                </div>
            </div>
        }
        {!layoutLoad && 
        <aside className="rightSidebar">
            <div className="rightSidebarLayout">
                
                
                <div className="rightSidebarContainer">
                    {/* Close Sidebar Section */}
                    <div className="closeSection">
                        <div className="closeRightSidebar">
                            <span className="material-icons cursor-pointer" onClick={closeRightSidebar}>highlight_off</span>
                        </div>
                    </div>
                    {/* Budget Card Section */}
                    <div className="cardSection">
                        <div className="cardLayout">
                            <div className="cardHeader">
                                <div className="budgetTitleIcon">
                                    <h4 className="budgetTitle">Expected Expense</h4>
                                    <span className="material-icons infoIcon fs-22">info</span>
                                </div>
                            </div>
                            <div className="cardContent">
                                <div className="cardContentLayout">
                                    <div className="amountIcon">
                                        {currencySymbol === 'INR' ? 
                                       <span className="material-icons fs-20">currency_rupee</span> :
                                       <span className="material-icons-outlined fs-20">attach_money</span>
                                    }
                                       <span className="budgetAmount fs-18 fw-600">{budgetData}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Upcoming Card Section */}
                    <div className="cardSection mt-30 upcomingCard">
                        <div className="cardLayout">
                            <div className="cardHeader">
                                <div className="upcomingLayout">
                                    <h5 className="upcomingTitle text-center">Upcoming Payments</h5>
                                </div>
                            </div>
                            <div className="cardContent cardContentDetail noScrollBar">
                                <div className="cardContentLayout">
                                    <ul className="upcomingCardList">
                                        {Array.isArray(upcomingDataList) && upcomingDataList.length > 0 ? (
                                            upcomingDataList.map((list, index) => (
                                                <li className="upcomingCardItem" key={index}>
                                                    <div className="upcomingLayout">
                                                        <div className="upcomingIcon">
                                                            <h6 className="upcomingIconTitle" title={list.name}>{list.name.charAt(0)}</h6>
                                                        </div>
                                                        <div className="upcomingContent">
                                                            <div className="titleDateSection">
                                                                <p className="d-flex fs-14 fw-500" title={list.name}>{list.name}</p>
                                                                <span className="d-flex fs-12 fw-400">{handleDateFormate(list.renew_date)}</span>
                                                            </div>
                                                            <div className="remainingDays">
                                                                <span className="d-flex fs-12 fw-500">{list.remaining_days} days</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        ) : (
                                            <li className="upcomingCardItem">No upcoming payments</li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </aside>
        }
        </>
    )
}
export default RightSidebar;