import React, {  useState } from 'react';
import Sidebar from '../../../components/user/sidebar/sidebar';
import "../../../assets/css/user/profile/profile.css";
import UserHeader from '../../../components/user/userHeader/userHeader';
import RightSidebar from '../../../components/user/sidebar/rightSidebar';
import ProfileContent from '../profile/profileContent';
// import ProfileHead from './profileHead';

const Profile = (props) => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const [rightSidebarActive, setRightSidebarActive] = useState(false);
  const [item, setItem] = useState({});
  const [showPopup, setShowPopup] = useState(false);  
  const [editPopup, setEditPopup] = useState(false);
  const [profileActive, setProfileActive] = useState(false);
  const closeSideBar = () => {
    setSidebarActive(true);
  }

  return (
    <div className='dashboardContainer'>
      {/* Sidebar Section */}
      <section className={sidebarActive ? 'sidebarSection sidebarActive' : 'sidebarSection'}>
        <span className='mobileEvent' onClick={closeSideBar}></span>
        <Sidebar sidebarActive={sidebarActive} profileActive={profileActive} setProfileActive={setProfileActive}/>
      </section>
      <section className='pageContentSection'>
        <div className='headerSection'>
          <UserHeader sidebarActive={sidebarActive} setSidebarActive={setSidebarActive} rightSidebarActive={rightSidebarActive} setRightSidebarActive={setRightSidebarActive} />
        </div>
        <div className='profileContentSection p-16'>
          <ProfileContent setItem = {setItem} item = {item} setShowPopup={setShowPopup} showPopup = {showPopup} setRightSidebarActive={setRightSidebarActive} editPopup = {editPopup} setEditPopup = {setEditPopup} setProfileActive={setProfileActive} />
        </div>
      </section>
      {rightSidebarActive &&
        <section className='rightSidebarSection'>
          <RightSidebar rightSidebarActive={rightSidebarActive} setRightSidebarActive={setRightSidebarActive} setItem = {setItem} item = {item} editPopup = {editPopup} setEditPopup = {setEditPopup} setShowPopup={setShowPopup} showPopup = {showPopup} />
        </section>
      }
    </div>
  )
}
export default Profile