import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/global/smarty-pays-logo.png';
import apiClient from '../../apiClient';
import { useGoogleLogin } from '@react-oauth/google';
import loadLogo from '../../../assets/images/global/sidebar-logo.png';
// import axios from 'axios';
// import Cookies from "js-cookie";

const Register = () => {
    let navigate = useNavigate();
    const [ user, setUser ] = useState([]);
    const [layoutLoad, setLayoutLoad] = useState(false);
    const googleLogin = async (e) => {
        if (user) {
            await apiClient.post('/api/login/google/callback', 
            { token: user.access_token }, 
            { headers: { 'Content-Type': 'application/json' }, timeout: 10000 }
            ).then((res) => {
                    if(res.status === 200){     
                        setValidate(true);   
                        setTimeout(closePoup, 3000);           
                        localStorage.setItem('token',res.data.data.token); 
                        localStorage.setItem('userEmail',res.data.data.user.email); 
                        localStorage.setItem('userCountry',res.data.data.user.country);
                        localStorage.setItem('userName',res.data.data.user.firstname); 
                        localStorage.setItem('currencyCode',res.data.data.user.currency); 
                        navigate('/dashboard');
                    }
                })
                .catch((err) => console.error(err));
        }
    };

    
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUser(codeResponse);
            googleLogin(codeResponse);
        },
        onError: (error) => console.error('Login Failed:', error)
    });

    const [initialstate, setInitialstate] = useState({
        firstname: '',
        email: '',
        password: '',
        password_confirmation: ''
    });
    const [formData, setFormData] = useState({
        firstname: '',
        email: '',
        password: '',
        password_confirmation: ''
    });
    const [error, setError] = useState();
    const [validate, setValidate] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        // Validate passwords as the user types
        if (name === 'password' || name === 'password_confirmation') {
            validatePasswords(name, value);
        }
    };
    const closePoup = () => {        
        setValidate(false);
    }
    const [passwordType, setPasswordType] = useState("password");
    const [passwordConfirmationType, setPasswordConfirmationType] = useState("password");
    const togglePassword = () => {
        setPasswordType(prevType => prevType === "password" ? "text" : "password");
    };

    const togglePasswordConfirmation = () => {
        setPasswordConfirmationType(prevType => prevType === "password" ? "text" : "password");
    };
    const validatePasswords = () => {
        if (formData.password !== formData.password_confirmation) {
            setValidate(true); 
            setError('Passwords do not match.');
            setErrorMessage('Passwords do not match');         
            setValidate(true);   
        } else {            
            setError('');
            setErrorMessage('');
            setValidate(false);
        }
    };
    const handleOnSubmit = async (e) => {
        e.preventDefault();
        let element = document.getElementById('fmInput');
        let elementInput = element.querySelectorAll("input:invalid");
    
        if (elementInput.length === 0) { 
            try {
                const response = await apiClient.post('/api/register', formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    timeout: 5000 // optional timeout
                });
                const resData = response.data;
                if (resData.status === 'success') {
                    localStorage.setItem('token', resData.data.token);
                    localStorage.setItem('userName', resData.data.user.firstname);
                    localStorage.setItem('userEmail', resData.data.user.email);
                    navigate('/country');
                } else {
                    setInitialstate();
                    setFormData(initialstate);         
                    setValidate(true);   
                    setError(response.message);  
                    setTimeout(closePoup, 3000);
                }
            } catch (error) {
                // Log error details for debugging
                console.error('Error during API request:', error);
                setError('An error occurred. Please try again later.');
                setTimeout(closePoup, 3000);
            }
        } else { 
            document.getElementById('fmInput').classList.add('errorInput');  
            setValidate(true);       
            setError('Field is required');   
            setTimeout(closePoup, 3000);
        }
    };
    
    return(
        <>
        {layoutLoad && 
          <div className="loadSection">
              <div className="loadLayout">                        
                <div className="loader"></div>
                <img src={loadLogo} alt="Smarty Pays Logo" className="loadLogo" />
              </div>
          </div>
        }            
        {!layoutLoad && 
        <div className="loginLayout w-100">
            <div className="loginLogoSection">
                <Link to="/">
                    <img src={Logo} alt="Smarty Pay Logo" className='loginLogo' width="100%" height="100%" />
                </Link>
            </div>
            <div className="headerSection p20-y pl-20">
                <h1 className='loginTitle pb-16'>Register</h1>
                <p>Track your subscription, and save your money with Smarty Pays</p>
            </div>
            <div className="formSection pl-20">
                {validate ?     
                    <div className="errorMessageLayout" id='errorMessageLayout'>
                        <div className='errorMessageSection'>
                            <p className="errorMessage fs-18 fw-500">{error}</p>
                            <span className="material-icons-outlined closeIcon" onClick={closePoup}>close</span>
                        </div>
                    </div> : ''
                }
               <form>
                   <div className='fmInput' id='fmInput'>
                        <div className="formControl pb-16">
                            <label htmlFor="firstName" className='d-block pb-14'>First Name *</label>
                            <input type="text" name="firstname" className="firstName" id="firstName" placeholder='User Name' value={formData.firstname} onChange={(e) => handleOnChange(e)} required />
                        </div>
                        <div className="formControl pb-16">
                            <label htmlFor="email" className='d-block pb-14'>Email *</label>
                            <input type="email" name="email" className="email" id="email" placeholder='demo@gmail.com' value={formData.email} onChange={(e) => handleOnChange(e)} required />
                        </div>
                        <div className="formControl pb-16">
                            <label htmlFor="password" className='d-block pb-14'>Password *</label>
                            <input type={passwordType} name="password" className="password" id="password" placeholder='******' value={formData.password} onChange={(e) => handleOnChange(e)} required onBlur={validatePasswords} />
                            <span className="material-icons-outlined eyeIcon" onClick={togglePassword}>
                                { passwordType==="password" ? 'visibility_off' : 'visibility' }
                            </span>
                        </div>
                        <div className="formControl pb-16">
                            <label htmlFor="password" className='d-block pb-14'>Password Confirmation *</label>
                            <input type={passwordConfirmationType} name="password_confirmation" className="password" id="password" placeholder='******' value={formData.password_confirmation} onChange={(e) => handleOnChange(e)} required onBlur={validatePasswords} />
                            <span className="material-icons-outlined eyeIcon" onClick={togglePasswordConfirmation}>
                                { passwordConfirmationType==="password" ? 'visibility_off' : 'visibility' }
                            </span>
                        </div>
                        <p className="errorMessage1 fs-12 fw-500 m-0">{errorMessage}</p>
                    </div>
               </form>               
               <div className="btnSection pt-16">
               <button type="submit" className="loginBtn fs-16" onClick={handleOnSubmit}>Register</button>
                </div>
                <div className='speseparatorSection'>
                    <span>--------------------</span>
                    <span className='textOR'>OR</span>
                    <span>--------------------</span>
                </div>
                <div className='googleLoginBtn'>
                    <button onClick={login}>Sign in with Google 🚀 </button>
                </div>
               <div className='p30-y text-center'>
                <p className='fs-14'>Already have an account? <span className='cursor-pointer currentLink'><Link to='/login'>Login</Link></span></p>
               </div>
            </div>
        </div>
        }
        </>
    )
}

export default Register;