import React from 'react';
import "./../../../src/assets/css/user/pageNotFound.css";
import { Link, useNavigate  } from 'react-router-dom';

const PageNotFound = () => {
  const navigate  = useNavigate ();
  const goBack = () => {
    navigate(-1);
  };
  const token = localStorage.getItem("token");
  return (
    <div id="notfound">
    <div className="notfound">
    <div className="notfound-404">
    <h1>404</h1>
    <h2>Page not found</h2>
    </div>
    {/* <Link to={"/"}>Homepage</Link> */}
    <button onClick={goBack} className="goBackBtn">Go Back</button>
    </div>
    </div>
    
  )
}

export default PageNotFound