import { useState } from "react";
import TabContent from "./tabContent";
import TabNavItem from "./tabNavList";
import "../../../assets/css/user/tabs/tabs.css";
import DashboardTab from "../../../pages/user/dashboard/dashboardTab";
import NoRecordFound from "../../../assets/images/global/no-sub-found.jpg";
const Tabs = ({showPopup,setShowPopup,apiCall,setAPICall,setItem,setEditPopup,setDeletePopup,deletePopup,setUpcomingData,setDeleteId,setBudget}) => {
    const [activeTab, setActiveTab] = useState('all');
    return (
      <div className="tabsSection">
        {/* Tab nav */}
        <ul className="tabNavication d-flex">
            <TabNavItem title="All" id="all" activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabNavItem title="Personal" id="personal" activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabNavItem title="Business" id="business" activeTab={activeTab} setActiveTab={setActiveTab} />
        </ul>
        <div className="outlet tabContentOutlet noScrollBar pb-20">
          {/* content will be shown here */}
          <TabContent id="all" activeTab={activeTab}>
            <DashboardTab activeTab={activeTab} showPopup={showPopup} deletePopup={deletePopup} setShowPopup={setShowPopup} setDeletePopup={setDeletePopup} apiCall={apiCall} setAPICall={setAPICall} setItem={setItem} setEditPopup={setEditPopup} setUpcomingData={setUpcomingData} setBudget={setBudget} setDeleteId={setDeleteId} />
          </TabContent>
          <TabContent id="personal" activeTab={activeTab}>            
            <DashboardTab activeTab={activeTab} showPopup={showPopup} deletePopup={deletePopup} setShowPopup={setShowPopup} setDeletePopup={setDeletePopup} apiCall={apiCall} setAPICall={setAPICall} setItem={setItem} setEditPopup={setEditPopup} setDeleteId={setDeleteId} />
           </TabContent>
           <TabContent id="business" activeTab={activeTab}>            
            <DashboardTab activeTab={activeTab} showPopup={showPopup} deletePopup={deletePopup} setShowPopup={setShowPopup} setDeletePopup={setDeletePopup} apiCall={apiCall} setAPICall={setAPICall} setItem={setItem} setEditPopup={setEditPopup} setDeleteId={setDeleteId} />
           </TabContent>
        </div>
      </div>
    );
};
export default Tabs;