import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import loadLogo from '../../../assets/images/global/sidebar-logo.png';
import Logo from '../../../assets/images/global/smarty-pays-logo.png';
import apiClient from '../../apiClient';
let token;
const Country = () => {
    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        country: '',
        currency: '',
        timezone: ''
    }); 
    const [errors, setErrors] = useState({
        country: '',
        currency: '',
        timezone: ''
    });
    const [validate, setValidate] = useState(false);
    const [error, setError] = useState();
    const [countryList, setCountryList] = useState({});
    const [timeZone, setTimeZone] = useState();
    const [countryCode, setCountryCode] = useState();
    const [layoutLoad, setLayoutLoad] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const validateForm = () => {
        let tempErrors = {};
          if (!formData.country) {
            tempErrors.country = 'Country is required';
          } 
          if (!formData.currency) {
            tempErrors.currency = 'Currency code is required';
          }
          if (!formData.timezone) {
            tempErrors.timezone = 'Time zone is required';
          } 
        setErrors(tempErrors);
        return Object.values(tempErrors).length === 0;
    };
    const handleOnChange = (e) => {
        if(e.name === 'country'){
            setDisabled(true);
            const countryCodeOption = countryList.find(option => option.name === e.value);
            setTimeZone(countryCodeOption.timeZone);
            setCountryCode(countryCodeOption.currency_code);
            localStorage.setItem('symbol', countryCodeOption.currency_symbol);
            setFormData({
                ...formData,
                country: e.value,
                currency: countryCodeOption.currency_code,
                timezone: countryCodeOption.timeZone
            });
            setDisabled(false);
        }
        if(e.name === 'currency'){
            setDisabled(false);
            setCountryCode(e.value);
            setFormData({
                ...formData,
                currency: e.value
            });
        }
        if(e.name === 'timezone'){
            setDisabled(false);
            setTimeZone(e.value);
            setFormData({
                ...formData,
                timezone: e.value
            });
        }
           
    }
    const closePoup = () => {        
        setValidate(false);
    }

    useEffect(() => {
        const fetchCountryData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await apiClient.get('/api/common', {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    },
                    timeout: 10000
                });
                if (response.status === 200) {
                    const json = response.data;
                    setCountryList(json.countries);
                    setLayoutLoad(false);
                }
            } catch (error) {
                console.error("There was an error fetching the data:", error);
                // Handle the error accordingly
            }
        };

        fetchCountryData();
    }, []);

    const handleOnSubmit = async (e) => {
        token = localStorage.getItem('token');
        e.preventDefault();
        if (validateForm()) {            
            await apiClient.put('/api/manage', formData, { headers: {"Authorization" : `Bearer ${token}`}, timeout: 10000})
            .then(response => {    
                const resData = response; 
                if(resData.status === 200){
                    localStorage.setItem('userCountry', resData.data.data.country); 
                    localStorage.setItem('currencyCode', resData.data.data.currency);
                    localStorage.setItem('emailVerify', null);
                    setValidate(true);   
                    setError(resData.data.message);  
                    setTimeout(closePoup, 3000); 
                    navigate('/dashboard');
                }
            })
            .catch(error => {
                if (error.response) {
                    setValidate(true);   
                    setError(error.response.data.message);  
                    setTimeout(closePoup, 3000);
                }
            });
        }
    }
    return(
        <div className="countryMainContainer w-100">
        {layoutLoad && 
            <div className="loadSection">
                <div className="loadLayout">                        
                    <div className="loader"></div>
                    <img src={loadLogo} alt="Smarty Pays Logo" className="loadLogo" />
                </div>
            </div>
        }   
        {!layoutLoad && 
        <div className="loginLayout w-100">
            <div className="logoSection">
                <img src={Logo} alt="Smarty Pay Logo" className='loginLogo' width="100%" height="100%" />
            </div>
            <div className="headerSection p20-y pl-20">
                <h1 className='loginTitle pb-16'>Country</h1>
                <p>Track your subscription, and save your money with Smarty Pays</p>
            </div>
            <div className="formSection pl-20">
                {validate ?     
                    <div className="errorMessageLayout" id='errorMessageLayout'>
                        <div className='errorMessageSection'>
                            <p className="errorMessage fs-18 fw-500">{error}</p>
                            <span className="material-icons-outlined closeIcon" onClick={closePoup}>close</span>
                        </div>
                    </div> : ''
                } 
                
               <form>
                    <div className='fmInput' id='fmInput'>
                        <div className="formControl pb-16">
                           <label htmlFor="currencySymbol" className='d-block pb-14'>Country *</label>
                            <Select isClearable 
                            onChange={(e) => handleOnChange(e)}
                            className="selectError"
                            options={countryList.map(e => ({ label: e.name, value: e.name, name:'country'}))}
                            />
                            {errors.country && <p className='errorMessageValidate' >{errors.country}</p>}
                        </div>
                        <div className="formControl pb-16">
                            <label htmlFor="currencySymbol" className='d-block pb-14'>Currency Code *</label>
                            <Select isClearable 
                            onChange={(e) => handleOnChange(e)}
                            className="selectError"
                            options={countryList.map(e => ({ label: e.currency_code, value: e.currency_code, name: 'currency'}))}
                            value={countryCode ? { label: countryCode, value: countryCode, name: 'currency' } : null}
                            isDisabled={disabled}
                            />
                            {errors.currency && <p className='errorMessageValidate' >{errors.currency}</p>}
                        </div>
                        <div className="formControl pb-16">
                            <label htmlFor="currencySymbol" className='d-block pb-14'>Time Zone * </label>
                            <Select isClearable 
                            onChange={(e) => handleOnChange(e)}
                            className="selectError"
                            options={countryList.map(e => ({ label: e.timeZone, value: e.timeZone, name: 'timezone'}))}
                            value={timeZone ? { label: timeZone, value: timeZone, name: 'timezone' } : null}
                            isDisabled={disabled}
                            />
                            {errors.timezone && <p className='errorMessageValidate' >{errors.timezone}</p>}
                        </div>
                    </div>
               </form>               
               <div className="btnSection pt-16">
                        <button type="submit" className="loginBtn fs-16" onClick={handleOnSubmit}>Submit</button> 
                    </div>
                {/* <div className='p30-y text-center'>
                    <span className="material-icons-outlined arrowCircle cursor-pointer">arrow_circle_left</span>
                </div> */}
            </div>
        </div>
        }
        </div>
    )
}

export default Country;