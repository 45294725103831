import React, { useState, useEffect } from 'react';
import Sidebar from '../../../components/user/sidebar/sidebar';
import "../../../assets/css/user/dashboard/dashboard.css";
import UserHeader from '../../../components/user/userHeader/userHeader';
import DashboardContent from './dashboardContent';
import RightSidebar from '../../../components/user/sidebar/rightSidebar';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const country = localStorage.getItem('userCountry');
  const currency = localStorage.getItem('currencyCode');
  useEffect(() => {
    if (token) {
      navigate('/dashboard');
    } else {
      navigate('/country');
    }
  }, [token, country, currency, navigate]);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [rightSidebarActive, setRightSidebarActive] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [upcomingData, setUpcomingData] = useState({});  
  const [budget, setBudget] = useState();
  const closeSideBar = () => {
    setSidebarActive(false);
  }
  const [item, setItem] = useState({});
  const [editPopup, setEditPopup] = useState(false);
  return (
    <div className='dashboardContainer'>
      {/* Sidebar Section */}
      <section className={sidebarActive ? 'sidebarSection sidebarActive' : 'sidebarSection'}>
        <span className='mobileEvent' onClick={closeSideBar}></span>
        <Sidebar sidebarActive={sidebarActive} />
      </section>
      <section className='pageContentSection'>
        <div className='headerSection'>
          <UserHeader sidebarActive={sidebarActive} setSidebarActive={setSidebarActive} rightSidebarActive = {rightSidebarActive} setRightSidebarActive = {setRightSidebarActive}/>
        </div>
        <div className='dashboradContentSection p-16'>
            <DashboardContent setUpcomingData={setUpcomingData} setBudget={setBudget} setItem = {setItem} item = {item} editPopup = {editPopup} setEditPopup = {setEditPopup} setShowPopup={setShowPopup} showPopup = {showPopup} setRightSidebarActive={setRightSidebarActive} />         
        </div>  
      </section>
      { rightSidebarActive &&       
        <section className='rightSidebarSection'>
          <RightSidebar rightSidebarActive = {rightSidebarActive} setRightSidebarActive = {setRightSidebarActive} upcomingData={upcomingData} budget={budget} setItem = {setItem} setEditPopup = {setEditPopup} setShowPopup={setShowPopup} />
        </section>
      }
    </div>
  )
}
export default Dashboard