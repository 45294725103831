import { Link, Outlet } from "react-router-dom";
import '../../../assets/css/web/header/header.css';
import Logo from '../../../assets/images/global/smarty-pays-logo.png';
import apiClient from '../../../pages/apiClient';
const Header = () => {
    const token = localStorage.getItem('token');
    const profileImage = localStorage.getItem('profileImg');
    const userName = localStorage.getItem('userName');
    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            const response = await apiClient.post('/api/logout', {}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                // Clear local storage
                localStorage.clear();   
                // Redirect to home page
                window.location.href = "/";
            }
        } catch (error) {
            console.error("There was an error during logout:", error);
            // Handle the error accordingly
        }
    };
    return(
        <header className="headerSection">
            <nav className="navibarSection">
                <div className="navbarLayout max-width-layout">
                    <div className="logoSection">
                        <Link to="/">
                            <img src={Logo} alt="Smarty Pay Logo" className="logo" width="100%" height="100%" />
                        </Link>
                    </div>
                    <ul className="mainMenu">
                        {token ? 
                            <div className="dropdown">
                                {profileImage ? 
                                    <img src={profileImage} alt={userName} className="profileImg" />
                                    :
                                    <span className="material-icons profileImgIcon">face</span>
                                }
                                <div className="dropdown-content">
                                    <Link to="/dashboard">Dashboard</Link>
                                    <Link to="/profile">Profile</Link>
                                    <Link onClick={handleLogout}>Logout</Link>
                                </div>
                            </div> : 
                            <li className="login pr-10 cursor-pointer current-link">
                                <Link to="/login">Login</Link>
                            </li>
                        }
                        <li className="signup cursor-pointer current-link">
                            <Link to="/register">Signup for free</Link>
                        </li>
                    </ul>
                </div>
            </nav>  
            <Outlet />
        </header>
    )
}

export default Header;