import '../../../assets/css/web/login/login.css';
import Banner from "./banner";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Country from "./country";
const CountryPage = () => {
    return(
       <div className="loginContainer d-flex">
         <section className="bannerSection w-50">
            <Banner />
         </section>
         <section className="formLayoutSection w-50">  
            <Routes>
               <Route index element={<Country/>} />
            </Routes>
         </section>
       </div>
    )
}

export default CountryPage;