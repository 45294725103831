import React, { useState } from 'react';
import Sidebar from '../../../components/user/sidebar/sidebar';
import UserHeader from '../../../components/user/userHeader/userHeader';
import RightSidebar from '../../../components/user/sidebar/rightSidebar';
import CalendarContent from './calendarContent';
import "./../../../assets/css/user/calendar/calendar.css";
const Calendar = () => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const [rightSidebarActive, setRightSidebarActive] = useState(false);
  const [item, setItem] = useState({});
  const [showPopup, setShowPopup] = useState(false);  
  const [editPopup, setEditPopup] = useState(false);
  const closeSideBar = () => {
    setSidebarActive(false);
  }
//   useEffect(() => {
//     fetch("https://app.smartypays.com/api/dashboard",{headers: {"Authorization" : `Bearer ${token}`}})
//     .then(response => response.json())
//     .then(json => {
//         let subscriptionListData = json.datas.subscriptionList;
//         setSubscriptionList(subscriptionListData);
//     })
// },[])
  return (
    <div className='calendarContainer'>
      {/* Sidebar Section */}
      <section className={sidebarActive ? 'sidebarSection sidebarActive' : 'sidebarSection'}>
        <span className='mobileEvent' onClick={closeSideBar}></span>
        <Sidebar sidebarActive={sidebarActive} />
      </section>
      <section className='pageContentSection'>
        <div className='headerSection'>
          <UserHeader sidebarActive={sidebarActive} setSidebarActive={setSidebarActive} rightSidebarActive = {rightSidebarActive} setRightSidebarActive = {setRightSidebarActive}/>
        </div>
        <div className='calendarContentSection p-16'>
           <CalendarContent setItem = {setItem} item = {item} editPopup = {editPopup} setEditPopup = {setEditPopup} setShowPopup={setShowPopup} showPopup = {showPopup} setRightSidebarActive={setRightSidebarActive}  />
        </div>  
      </section>
      { rightSidebarActive &&       
        <section className='rightSidebarSection'>
          <RightSidebar rightSidebarActive = {rightSidebarActive} setRightSidebarActive = {setRightSidebarActive} setItem = {setItem} item = {item} editPopup = {editPopup} setEditPopup = {setEditPopup} setShowPopup={setShowPopup} showPopup = {showPopup} />
        </section>
      }
    </div>
  )
}
export default Calendar