import React, { useEffect, useState } from 'react';
import Sidebar from '../../../components/user/sidebar/sidebar';
import "../../../assets/css/user/dashboard/dashboard.css";
import UserHeader from '../../../components/user/userHeader/userHeader';
import RightSidebar from '../../../components/user/sidebar/rightSidebar';
import UploadContent from './uploadContent';



const token = localStorage.getItem('token');
const Upload = () => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const [rightSidebarActive, setRightSidebarActive] = useState(false);
  const [item, setItem] = useState({});
  const [showPopup, setShowPopup] = useState(false);  
  const [editPopup, setEditPopup] = useState(false);
  const closeSideBar = () => {
    setSidebarActive(false);
  }
 
  return (
    <div className={sidebarActive ? 'uploadContainer sidebarActive' : 'uploadContainer'}>
      {/* Sidebar Section */}
      <section className={sidebarActive ? 'sidebarSection sidebarActive' : 'sidebarSection'}>
        <span className='mobileEvent' onClick={closeSideBar}></span>
        <Sidebar sidebarActive={sidebarActive} />
      </section>
      <section className='pageContentSection'>
        <div className='headerSection'>
          <UserHeader sidebarActive={sidebarActive} setSidebarActive={setSidebarActive} rightSidebarActive = {rightSidebarActive} setRightSidebarActive = {setRightSidebarActive}/>
        </div>
        <div className='uploadContentSection p-16'>       
          <UploadContent setShowPopup={setShowPopup} setItem = {setItem} item = {item} showPopup = {showPopup} setRightSidebarActive={setRightSidebarActive} editPopup = {editPopup} setEditPopup = {setEditPopup} />  
        </div>  
      </section>
      { rightSidebarActive &&       
        <section className='rightSidebarSection'>
          <RightSidebar rightSidebarActive = {rightSidebarActive} setRightSidebarActive = {setRightSidebarActive} setItem = {setItem} item = {item} editPopup = {editPopup} setEditPopup = {setEditPopup} setShowPopup={setShowPopup} showPopup = {showPopup}  />
        </section>
      }
    </div>
  )
}
export default Upload