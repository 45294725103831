import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./pages/web/landingPage/landingPage";
import LoginPage from "./pages/web/login/loginPage";
import RegisterPage from "./pages/web/login/registerPage";
import CountryPage from "./pages/web/login/countryPage";
import ForgotPasswordPage from "./pages/web/login/forgetPasswordPage";
import Dashboard from "./pages/user/dashboard/dashboard";
import Upload from "./pages/user/upload/upload";
import Calendar from "./pages/user/calendar/calendar";
import Profile from "./pages/user/profile/profile";
import PrivateRoute from "./pages/web/privateRoute";
import PageNotFound from "./pages/user/PageNotFound";
import userContext from "./pages/user/profile/userContext";
import UserDetail from "./pages/web/login/userDetail";
import VerifyEmail from "./pages/web/login/verifyEmail";
import ContactUs from "./pages/web/page/contactUs";
import TermsConditions from "./pages/web/page/termsConditions";
import PrivacyPolicy from "./pages/web/page/privacyPolicy";
function App() {  
  return (
    <userContext.Provider value={{}}>
    <div className="mainContainer">
        <Routes>  
          <Route index element={<LandingPage />} />
          <Route path="/contact-us" element={<ContactUs />} /> 
          <Route path="/terms-conditions" element={<TermsConditions />} /> 
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
          <Route path="/login" element={<LoginPage />} /> 
          <Route path="/register" element={<RegisterPage />} />      
          <Route path="/forgotPassword" element={<ForgotPasswordPage />} />   
          <Route path="/userDetail" element={<UserDetail />} />    
          <Route path="/verify-email" element={<VerifyEmail />} /> 
          <Route exact path='/' element={<PrivateRoute/>}>          
            <Route path="/country" element={<CountryPage />} />  
            <Route path="/dashboard" element={<Dashboard />} />      
            <Route path="/calendar" element={<Calendar/>}/>
            <Route path="/uploads" element={<Upload/>}/>
            <Route path="/profile" element={<Profile/>}/>
            <Route path="*" element={<PageNotFound/>} />
          </Route>
        </Routes>
        {/* <PrivateRoute path="/dashboard" component={Dashboard} /> */}
    </div>
    </userContext.Provider>
  );
}

export default App;
