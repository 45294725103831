import '../../../assets/css/web/login/login.css';
import Banner from "./banner";
import Register from "./register";
const RegisterPage = () => {
    return(
       <div className="loginContainer d-flex">
         <section className="bannerSection w-50">
            <Banner />
         </section>
         <section className="formLayoutSection w-50">  
         <Register/>
         </section>
       </div>
    )
}

export default RegisterPage;