import React, { useEffect, useState, useLayoutEffect } from 'react';
import "../../../assets/css/user/popup/popup.css";
import CreatableSelect from 'react-select/creatable';
import loadLogo from '../../../assets/images/global/sidebar-logo.png';
// import Axios from 'axios';
import apiClient from '../../../pages/apiClient';
import { useNavigate } from 'react-router-dom';
const SubscriptionPopup = ({ item, setShowPopup, setAPICall, apiCall, editPopup, setEditPopup, setCalendarSub, calendarSub, setRightSidebarActive,  }) => {
  const [disableInput, setDisableInput] = useState(true);
  const [categoryType, setCategoryType] = useState('personal'); 
  // let token, currencyCode;  
  let token;
  let currencyCode;
  const [data, setData] = useState( {
    name: '',
    renew_date: '',
    renew_type: '',
    price: '',
    category: '',
    type: 'personal',
    currency: localStorage.getItem('currencyCode'),
    is_remind: 0,
    remind_type1: null,
    remind_days1: null,
    remind_type2: null,
    remind_days2: null
  });
  const navigate = useNavigate(); 
  const [errors, setErrors] = useState({
    name: '',
    renew_date: '',
    renew_type: '',
    price: '',
    category: '',
  });

  const validateForm = () => {
    let tempErrors = {};
    if(editPopup){
      if (!editData.name) {
        tempErrors.name = 'Subscription Name is required';
      } 
      if (!editData.renew_date) {
        tempErrors.renew_date = 'Renewal Date is required';
      }
      if (!editData.renew_type) {
        tempErrors.renew_type = 'Subscription Type is required';
      } 
      if (!editData.price) {
        tempErrors.price = 'Price is required';
      } 
      if (!editData.category) {
        tempErrors.category = 'Subscription Category is required';
      }
    }else {
      if (!data.name) {
        tempErrors.name = 'Subscription Name is required';
      } 
      if (!data.renew_date) {
        tempErrors.renew_date = 'Renewal Date is required';
      }
      if (!data.renew_type) {
        tempErrors.renew_type = 'Subscription Type is required';
      } 
      if (!data.price) {
        tempErrors.price = 'Price is required';
      } 
      if (!data.category) {
        tempErrors.category = 'Subscription Category is required';
      }
    } 
    setErrors(tempErrors);
    return Object.values(tempErrors).length === 0;
  };

 

  let remindMeTypes = [];
  let remindMeDays = [];
  let remindMeTypes1 = [];
  let remindMeDays1 = [];
  if (editPopup) {
    remindMeTypes = item.reminders[0]?.remind_type || '';
    remindMeDays = item.reminders[0]?.remind_days || '';
    remindMeTypes1 = item.reminders[1]?.remind_type || '';
    remindMeDays1 = item.reminders[1]?.remind_days || '';
  }

   // Edit Value 

   const [editData, setEditData] = useState( {
    id: editPopup ? item.id : '',
    name: editPopup ? item.name : '',
    renew_date: editPopup ? item.renew_date : '',
    renew_type: editPopup ? item.renew_type : '',
    price: editPopup ? item.price : '',
    category: editPopup ? item.category : '',
    type: editPopup ? item.type : '',
    currency: editPopup ? item.currency : 'INR',
    is_remind: editPopup ? item.is_remind : 0,
    remind_type1:editPopup ? remindMeTypes : '',
    remind_days1: editPopup ? remindMeDays : '',
    remind_type2: editPopup ? remindMeTypes1 : '',
    remind_days2: editPopup ? remindMeDays1 : ''
  });

  const [reminder, setReminder] = useState(0);
  const [subscriptionName, setSubscriptionName] = useState([]);
  const [subscriptionCate, setSubscriptionCate] = useState([]);
  const [editCate, setEditCate] = useState([]);
  // const [subscriptionType, setSubscriptionType] = useState([]);
  const [addReminder, setAddReminder] = useState(true);
  const [removeReminder, setRemoveReminder] = useState(false);
  const [resData, setResData] = useState([]);
  const [layoutLoad, setLayoutLoad] = useState(true);
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState();
  const [reminderNum, setReminderNum] = useState(true);
  const [reminderNum1, setReminderNum1] = useState(true);
  const [remindOptionsNum, setRemindOptionsNum] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const remindOptions = [
    { value: 'days', text: 'Days' },
    { value: 'weeks', text: 'Weeks' },
    { value: 'months', text: 'Months' }
  ];

const subscriptionType = [
  {
      "key": "1",
      "value": "Monthly"
  },
  {
      "key": "3",
      "value": "Quaterly"
  },
  {
      "key": "12",
      "value": "Yearly"
  },
  {
      "key": "9",
      "value": "One time"
  }
];
function getLocalTime() {
  // Create a new Date object for the current time
  let date = new Date();

  // Get hours and minutes
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  // Format hours and minutes to always have two digits
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;


  // Combine hours and minutes in 'HH:MM' format
  let localTime = hours + ':' + minutes + ':' + seconds;

  return localTime;
}
  const handleChange = (e) => {
    let eventName, eventValue, eventValue1;
    setReminderNum(true);
    if(e.name === 'remind_type1'){
      if(e.value === 'days'){
        setRemindOptionsNum( [
          { value: '1', text: '1' },
          { value: '2', text: '2' },
          { value: '3', text: '3' },
          { value: '4', text: '4' },
          { value: '5', text: '5' },
          { value: '6', text: '6' },
        ])      
        setReminderNum(false);
      }
      else if(e.value === 'weeks'){
        setRemindOptionsNum( [
          { value: '1', text: '1' },
          { value: '2', text: '2' },
          { value: '3', text: '3' },
          { value: '4', text: '4' },
        ])            
        setReminderNum(false);
      }
      else if(e.value === 'months'){
        setRemindOptionsNum( [
          { value: '1', text: '1' },
          { value: '2', text: '2' },
          { value: '3', text: '3' },
          { value: '4', text: '4' },
          { value: '5', text: '5' },
          { value: '6', text: '6' },
          { value: '7', text: '7' },
          { value: '8', text: '8' },
          { value: '9', text: '9' },
          { value: '10', text: '10' },
          { value: '11', text: '11' },
          { value: '12', text: '12' },
        ])            
        setReminderNum(false);
      }
    }
    else if(e.name === 'remind_type2'){
      if(e.value === 'days'){
        setRemindOptionsNum( [
          { value: '1', text: '1' },
          { value: '2', text: '2' },
          { value: '3', text: '3' },
          { value: '4', text: '4' },
          { value: '5', text: '5' },
          { value: '6', text: '6' },
        ])      
        setReminderNum1(false);
      }
      else if(e.value === 'weeks'){
        setRemindOptionsNum( [
          { value: '1', text: '1' },
          { value: '2', text: '2' },
          { value: '3', text: '3' },
          { value: '4', text: '4' },
        ])            
        setReminderNum1(false);
      }
      else if(e.value === 'months'){
        setRemindOptionsNum( [
          { value: '1', text: '1' },
          { value: '2', text: '2' },
          { value: '3', text: '3' },
          { value: '4', text: '4' },
          { value: '5', text: '5' },
          { value: '6', text: '6' },
          { value: '7', text: '7' },
          { value: '8', text: '8' },
          { value: '9', text: '9' },
          { value: '10', text: '10' },
          { value: '11', text: '11' },
          { value: '12', text: '12' },
        ])            
        setReminderNum1(false);
      }
    }
    if(e.value){      
      if(e.__isNew__){
        eventName = 'name';
      }else{
        eventName = e.name;
      }
      eventValue = e.value;
      setData({ ...data, [eventName]: eventValue });
    }else{
      eventName = e.target.name;
      eventValue = e.target.value;
      if(eventName === 'renew_date'){
        eventValue1 = e.target.value;
        eventValue = `${eventValue1} ${getLocalTime()}`;
      }
      else if(eventName === 'type'){
        if(eventValue !== '' && eventValue != null && typeof eventValue != 'undefined'){
          setCategoryType(e.target.value);
        }else{
          setCategoryType('personal');
        }
      }
      else if(eventName === 'is_remind'){
        eventValue = e.target.checked ? 1 : 0;
        setReminder(eventValue);  
        setData({ ...data, [eventName]: eventValue });    
      }
      else{setData({ ...data, [eventName]: eventValue });}      
    }
    if(editPopup){
      let newEditData = { ...editData, [eventName]: eventValue };
      if (reminder === 0) {
        newEditData = {
          ...newEditData,
          is_remind: 0,
          remind_type1: '',
          remind_days1: '',
          remind_type2: '',
          remind_days2: ''
        };
      }
      setEditData(newEditData);
    }else{
      setData({ ...data, [eventName]: eventValue });
    }
  };
  useLayoutEffect(() => { 
    setLayoutLoad(true);
  },[]);

  const getDatePart = (datetime) => {
    return datetime.split(' ')[0];
};
  useEffect(() => {
    const fetchData = async () => {
      token = localStorage.getItem('token');
      currencyCode = localStorage.getItem('currencyCode');
      if (!token) {
        console.error("Token or currencyCode not found in sessionStorage");
        return;
      }

      try {
        // Fetch entries
        if (editPopup) {
          const entryResponse = await apiClient.get("/api/entries", {
            headers: { "Authorization": `Bearer ${token}` }
          });
          if(entryResponse){
            setData(item);
            setReminder(item.is_remind);
            setCategoryType(item.type.toLowerCase());
          }
          if (item.reminders.length === 2) {
            setRemoveReminder(true);
          }
        }

        // Fetch common data
        const commonResponse = await apiClient.get("/api/common", {
          headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" }
        });

        if (commonResponse.status === 200) {
          const json = commonResponse.data;
          setSubscriptionName(json.featured_entries);
          setSubscriptionCate(json.categories);

          if (editPopup && json.categories.length > 0 && item) {
            const matchedCategory = json.categories.find(e => e.id === item.category);
            setDefaultValue(
              matchedCategory 
                ? { label: matchedCategory.name, value: matchedCategory.id, name: "category" }
                : null
            );
          }
        }

      } catch (error) {
        console.error("There was an error!", error);
        // Handle error accordingly
      } finally {
        setLayoutLoad(false);
      }
    };

    fetchData();
  }, [editPopup, item]);


  const handleClose = () => {
    if(editPopup){
      setEditPopup(!editPopup);
    }
    if(calendarSub){
      setCalendarSub(!calendarSub);
    }
    setShowPopup(false);
    setRightSidebarActive(false);
    item = {};
  }         

  // Add Reminder 

  const handleAddReminder = () => {
    setRemoveReminder(true);
    setAddReminder(true);
  }

  // Remove Reminder

  const handleDeleteReminder = () => {
    setRemoveReminder(false);  
    setAddReminder(true);    
    let newEditData1 = {
      ...editData,
      remind_type2: '',
      remind_days2: ''
    };
    setEditData(newEditData1);
    setData(newEditData1);
  }
  const closePoup = () => {        
    setValidate(false);
  }


  const handleSave = async (e) => {
    e.preventDefault(); 
  //let element = document.getElementById('popupInput');
  // let elementInput = element.querySelectorAll("input:invalid");
    token = localStorage.getItem('token') || localStorage.getItem('token');
    currencyCode = localStorage.getItem('currencyCode') || localStorage.getItem('currencyCode');
    if (editPopup) {
      if (validateForm()) {
          try {
              const response = await apiClient.put(`/api/entry/${editData.id}`, editData, {
                  headers: {
                      "Content-Type": "application/json",
                      "Accept": "application/json",
                      "Authorization": `Bearer ${token}`
                  }
              });

              setResData(response.data.status);
              // setTimeout(closePoup, 3000);      
              navigate('/dashboard');

              if (calendarSub) {
                  setAPICall(apiCall);
                  navigate('/calendar');
              }
              setAPICall(!apiCall);
              handleClose();
          } catch (error) {
              console.error("error", error);
              setTimeout(closePoup, 3000); // Optional: if you need to delay closing the popup on error
          }
      } else {
          document.getElementById('popupInput').classList.add('errorInput');
          setValidate(true);
          setError('Field is required');
          setTimeout(closePoup, 3000);
      }
  }else{
        if (validateForm()) {
          try {
              const response = await apiClient.post('/api/entry', data, {
                  headers: {
                      "Content-Type": "application/json",
                      "Accept": "application/json",
                      "Authorization": `Bearer ${token}`
                  }
              });
  
              const resData = response.data;
  
              if (resData.status === 'success') {
                  setValidate(true);
                  setError(resData.message);
                  setTimeout(closePoup, 3000); 
                  navigate('/dashboard');
                  if (calendarSub) {
                      navigate('/calendar');
                  } else {
                      setAPICall(!apiCall);
                  }
                  handleClose();
              }
          } catch (error) {
              if (error.response) {
                  setValidate(true);
                  setError(error.response.data.message);
                  setTimeout(closePoup, 3000);
              } else {
                  console.error("There was an error!", error);
              }
          }
      } else {
          setValidate(true);
          setError('Field is required');
          setTimeout(closePoup, 3000);
      }
    }
  };

  const handleEdit = () => {
    setDisableInput(false);
  }
  const isValidNewOption = () => false;

  return (
    <div className='subscriptionMainContainer'>    
    {layoutLoad && 
      <div className="loadSection">
          <div className="loadLayout">                        
            <div className="loader"></div>
            <img src={loadLogo} alt="Smarty Pays Logo" className="loadLogo" />
          </div>
      </div>
    }            
    {!layoutLoad && 
    <section className='popupSection'>
        <div className='popupMainContainer'>
            <div className="popupContainer">
                {validate ?     
                  <div className="errorMessageLayout" id='errorMessageLayout'>
                      <div className='errorMessageSection'>
                          <p className="errorMessage fs-18 fw-500">{error}</p>
                          <span className="material-icons-outlined closeIcon" onClick={closePoup}>close</span>
                      </div>
                  </div> : ''
                }                
                <form className='popupForm'>
                    <div className='popupInput' id='popupInput'>
                      <div className='popupHeaderSection'>
                        <div className='formControl subscriptionPopup' id={item.type}>
                          {/* <input type="radio" name="type" id="personal" value="personal" checked = {categoryType === 'personal'} onChange={handleChange} />
                          <input type="radio" name="type" id="business" value="business" checked = {categoryType === 'business'} onChange={handleChange} />
                            <label htmlFor="personal" className="option personal">
                              <div className="dot"></div>
                                <span>Personal</span>
                                </label>
                            <label htmlFor="business" className="option business">
                              <div className="dot"></div>
                                <span>Business</span>
                            </label> */}
                            <label className='subscriptionPopupLabel'>
                              <input type="radio" name="type" id="personal" value="personal" checked = {categoryType === 'personal' || categoryType === 'Personal'} onChange={handleChange} disabled = {disableInput && editPopup ? "disabled" : ""} />
                              <span>Personal</span>
                            </label>
                            <label className='subscriptionPopupLabel'>
                              <input type="radio" name="type" id="business" value="business" checked = {categoryType === 'business' || categoryType === 'Business'} onChange={handleChange} disabled = {disableInput && editPopup ? "disabled" : ""} />
                              <span>Business</span>
                            </label>
                        </div>
                        <div className='closePoupSection'>
                          <div className='closeIconLayout'>
                            {editPopup &&                            
                            <span className="material-icons editIcon" title='Edit' onClick={handleEdit}>edit</span>
                            }
                            <span className="material-icons cancelIcon" title='Close' onClick={handleClose}>cancel</span>
                          </div>
                        </div>
                      </div>
                      <div className='popupInputLayout'>
                        <div className='formControl'>
                          <label>Subscription Name <span className='starValidate'>*</span></label>
                          <CreatableSelect isClearable 
                           className="selectError"
                           options={subscriptionName.map(e => ({ label: e.name, value: e.name, name: 'name'}))}
                           defaultValue={editPopup ? { label: item.name, value: item.name, name: 'name' } : ''}
                           onChange={handleChange}
                           isDisabled={editPopup}
                           />
                           {errors.name && <p className='errorMessageValidate' >{errors.name}</p>}
                        </div>
                        <div className='formControl'>
                          <label>Subscription Category <span className='starValidate'>*</span></label>
                          <CreatableSelect isClearable 
                           options={subscriptionCate.map(e => ({ label: e.name, value: e.id, name:"category"}))}
                           defaultValue={defaultValue}
                           onChange={handleChange}  
                           isDisabled={editPopup ? disableInput : ''}
                           isValidNewOption={isValidNewOption}
                          />
                          {errors.category && <p className='errorMessageValidate' >{errors.category}</p>}
                        </div>
                      </div>
                      <div className='popupInputLayout'>
                        <div className='formControl'>
                          <label htmlFor='priceSub'>Price <span className='starValidate'>*</span></label>
                          {editPopup ? (                            
                            <input type="number" name="price" required className='priceSub notSelect editPrice' value={editData.price} id="priceSub" onChange={handleChange} maxLength="4" disabled = {disableInput ? "disabled" : ""} />
                            ) : (                            
                            <input type="number" name="price" required className='priceSub notSelect addPrice' value={data.price} id="priceSub" onChange={handleChange} maxLength="4" />
                            )
                          }                          
                          {errors.price && <p className='errorMessageValidate' >{errors.price}</p>}
                        </div>
                        <div className='formControl'>
                          <label>Subscription Type <span className='starValidate'>*</span></label>
                          <CreatableSelect isClearable 
                          options={subscriptionType.map(e => ({ label: e.value, value: e.value, name:'renew_type'}))} 
                          defaultValue={editPopup ? { label: item.renew_type, value: item.renew_type, name:"renew_type" } : ''}
                          onChange={handleChange} 
                          isDisabled={editPopup ? disableInput : ''}
                          isValidNewOption={isValidNewOption}
                          />
                          {errors.renew_type && <p className='errorMessageValidate' >{errors.renew_type}</p>}
                        </div>
                      </div>
                      <div className='popupInputLayout'>
                        <div className='formControl'>
                          <label htmlFor='currency'>Currency</label>
                          <input type="text" name="currency" className='currency notSelect' id="currency" value={localStorage.getItem('currencyCode')} disabled />
                        </div>
                        <div className='formControl'>
                          <label htmlFor='renewalDate'>Next Renewal Date <span className='starValidate'>*</span></label>
                          <input type="date" name="renew_date" required min={new Date().toISOString().split('T')[0]} value={editPopup ? getDatePart(editData.renew_date) : getDatePart(data.renew_date)} className='renewalDate notSelect' id="renewalDate" onChange={handleChange} disabled = {editPopup ? "disabled" : ""} />
                          {errors.renew_date && <p className='errorMessageValidate' >{errors.renew_date}</p>}
                        </div>
                      </div>
                      <div className='popupInputLayout reminderLayout'>
                        <div className='formControl'>
                          <div className='switchControl'>
                            <span className='fs-14 pr-10 fw-500'>Reminders</span>
                            <label className="switch" htmlFor='reminders'>
                           
                            {editPopup ? (
                              <input
                                type="checkbox"
                                name="is_remind"
                                id="reminders"
                                checked={reminder === 1}
                                className="reminders"
                                onChange={handleChange}
                                disabled = {disableInput ? "disabled" : ""}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                name="is_remind"
                                id="reminders"
                                className="reminders"
                                onChange={handleChange}
                              />
                              
                            )}
                              <span className="slider round"></span>
                            </label>                    
                          </div>
                          {reminder !== 0 && (
                            <div className='reminderUserLayoout'>
                              <label>Reminders Me</label>
                              <div className='reminderMeSection'>
                                <div className='reminderList reminderMe'>
                                  <CreatableSelect isClearable 
                                  options={remindOptions.map(e => ({ label: e.text, value: e.value, name:'remind_type1'}))} 
                                  defaultValue={editPopup ? { label: remindMeTypes, value: remindMeTypes, name:"remind_type1" } : ''}
                                  onChange={handleChange} 
                                  isDisabled={editPopup ? disableInput : ''}
                                  isValidNewOption={isValidNewOption}
                                  />                                
                                </div>
                                <div className='reminderDay reminderMe'>
                                  <CreatableSelect isClearable 
                                  options={remindOptionsNum.map(e => ({ label: e.text, value: e.value, name:'remind_days1'}))} 
                                  defaultValue={editPopup ? { label: remindMeDays, value: remindMeDays, name:"remind_days1" } : ''}
                                  onChange={handleChange}
                                  isDisabled={reminderNum}
                                  isValidNewOption={isValidNewOption}
                                  />                                
                                </div>
                                <div className={addReminder ? 'addReminderIcon' : 'addReminderIcon visibilityHidden'} disabled = {disableInput && editPopup ? "disabled" : ""}>
                                  <span className="material-icons-round" onClick={handleAddReminder}>add_circle</span>
                                </div>  
                              </div>
                              
                              {/* Reminder Section 2 */}
                              {removeReminder && 
                                <div className='reminderMeSection pt-14' id='reminderSectionHideShow'>
                                  <div className='reminderList reminderMe'>
                                    <CreatableSelect isClearable 
                                    options={remindOptions.map(e => ({ label: e.text, value: e.value, name:'remind_type2'}))} 
                                    defaultValue={editPopup ? { label: remindMeTypes1, value: remindMeTypes1, name:"remind_type2" } : ''}
                                    onChange={handleChange} 
                                    isDisabled={editPopup ? disableInput : ''}
                                    isValidNewOption={isValidNewOption}
                                    />                                
                                  </div>      
                                  <div className='reminderDay reminderMe'>
                                    <CreatableSelect isClearable 
                                    options={remindOptionsNum.map(e => ({ label: e.text, value: e.value, name:'remind_days2'}))}
                                    defaultValue={editPopup ? { label: remindMeDays1, value: remindMeDays1, name:"remind_days2" } : ''} 
                                    onChange={handleChange}
                                    isDisabled={reminderNum1}
                                    isValidNewOption={isValidNewOption}
                                    />                                
                                  </div>                          
                                  <div className='deleteReminderIcon' disabled = {disableInput && editPopup ? "disabled" : ""}>
                                    <span className="material-icons-round" onClick={handleDeleteReminder} >delete</span>
                                  </div>
                                </div>
                              }
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                </form>
                <div className='btnGroup'>
                {editPopup ?
                    <button className='saveBtn' onClick={handleSave} disabled = {disableInput ? "disabled" : ""}>Update</button> : 
                    <button className='saveBtn' onClick={handleSave}>Save</button> }
                </div>
            </div>
        </div>      
    </section>
    }
    </div>
  );
};

export default SubscriptionPopup;