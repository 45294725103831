import '../../../assets/css/web/footer/footer.css';
import { Link } from "react-router-dom";
const Footer = () => {
    return(
        <footer className="footerSection">
            <div className="footerLayout">
                <ul className="footerMenu">
                    <li><Link to="/terms-conditions">Terms</Link></li>
                    <li><Link to="/privacy-policy">Privacy</Link></li>
                    <li><Link to="/contact-us">Contact</Link></li>
                </ul>
            </div>
            <div className="copyRightSection">
                <p>Copyright © 2023 All rights reserved. Smarty Pays</p>
            </div>
        </footer>
    )
}

export default Footer;