import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';

export default function UserDetail() {
    let navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    const userLogin = Cookies.get("data");
    const parsedUserLogin = JSON.parse(userLogin);
    setUserDetails(parsedUserLogin);
  },[]);
// log out function to log the user out of google and set the profile array to null
const logOut = () => {
    googleLogout();
    setUserDetails(null);
    navigate('/register')
};
  return (
    <>
      {userDetails ? (
        <div className="user-profile">
          <div className="card">
            <img
              src={userDetails.picture}
              alt={`${userDetails.given_name}'s profile`}
              title={`${userDetails.given_name}'s profile`}
              className="profile-pic"
            />
            <p>Welcome</p>
            <h1 className="name">{userDetails.name}</h1>
            <p className="email">{userDetails.email}</p>
            <button className="logoutButton" onClick={logOut}>Logout</button>
          </div>
        </div>
      ) : (
        <div>
          <h1>Loading...</h1>
        </div>
      )}
    </>
  );
}