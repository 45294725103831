import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/global/global.css';
import { BrowserRouter} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById('root'));
// const liveClientId = '254061407641-c3r949u3u1gon1o737824m6fjc4iqelb.apps.googleusercontent.com';
// const devClientId = '254061407641-6649omk3kkutfdc75bnhbem1gsi6hqtg.apps.googleusercontent.com';
root.render(
  
  <GoogleOAuthProvider clientId="254061407641-c3r949u3u1gon1o737824m6fjc4iqelb.apps.googleusercontent.com">
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>   
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
