import React, { useState, useEffect, useRef } from 'react';
import Logo from '../../../assets/images/global/smarty-pays-logo.png';
import SidebarLogo from '../../../assets/images/global/sidebar-logo.png';
import "../../../assets/css/user/sidebar/sidebar.css";
import { Link, useLocation } from 'react-router-dom';
import apiClient from '../../../pages/apiClient';
import loadLogo from '../../../assets/images/global/sidebar-logo.png';

const Sidebar = ({ sidebarActive, profileActive, setProfileActive }) => {

    const location = useLocation();
    const { pathname } = location;
    const profileImageRef = useRef(localStorage.getItem("profileImg"));
    const [profileUserName, setProfileUserName] = useState(localStorage.getItem("userName") || '');
    const[logoutValue, setLogoutValue] = useState(false);

    // Move state update logic to useEffect
    useEffect(() => {
        if (profileActive) {
            profileImageRef.current = localStorage.getItem("profileImg");
            setProfileUserName(localStorage.getItem("userName") || '');
            setProfileActive(false);
        }
    }, [profileActive, setProfileActive]);

    const handleLogout = async (e) => {
        e.preventDefault();
        setLogoutValue(true);
        const token = localStorage.getItem('token');
    
        try {
            const response = await apiClient.post('/api/logout', {}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                // Clear local storage
                localStorage.clear();   
                // Redirect to home page
                window.location.href = "/";
            }
            setLogoutValue(false);
        } catch (error) {
            console.error("There was an error during logout:", error);
            // Handle the error accordingly
        }
    };
    
    return (
        <>
            {logoutValue && 
                <div className="loadSection">
                    <div className="loadLayout">                        
                        <div className="loader"></div>
                        <img src={loadLogo} alt="Smarty Pays Logo" className="loadLogo" />
                    </div>
                </div>
            }
            <aside className={sidebarActive ? 'sidebarContainer activeSidebar' : 'sidebarContainer'}>
                {/* Logo Section */}
                <div className="userLogoSection">
                    <div className="logoLayout">
                        <Link to="/dashboard">
                            <img src={Logo} alt="Smarty Pays Logo" className="logo d-none" width="" height="" />
                            <img src={SidebarLogo} alt="Smarty Pays Logo" className="sidebarLogo" width="" height="" />
                        </Link>
                    </div>
                </div>
                {/* Content Layout */}
                <div className='sidebarContentLayout'>
                    {/* Profile Section */}
                    <div className={`userProfileSection pt-20 ${pathname === '/profile' ? 'active' : ''}`}>
                        <Link to="/profile">
                            <div className='userProfileLayout'>
                                {profileImageRef.current && profileImageRef.current !== 'null' ? (
                                    <img src={profileImageRef.current} alt='User Profile' className='userProfileImg' />
                                ) : (
                                    <div className='sidebarProfileImage'>
                                        <span className="material-icons">face</span>
                                    </div>
                                )}
                            </div>
                            <p className='text-center fs-14 fw-600 d-none pt-16 text-capitalize'>{profileUserName || ''}</p>
                        </Link>
                    </div>
                    {/* Menu Section */}
                    <div className='sidebarManuSection pt-30'>
                        <div className='sidebarMenuLayoout'>
                            <ul className='sidebarMenuList'>
                                <li className={`sidebarList ${pathname === '/dashboard' ? 'active' : ''}`} title='Dashboard'>
                                    <Link to="/dashboard">
                                        <span className="material-icons-outlined menuListIcon">dashboard</span>
                                        <span className='menuListText d-none'>Dashboard</span>
                                    </Link>
                                </li>
                                <li className={`sidebarList ${pathname === '/calendar' ? 'active' : ''}`} title='Calendar'>
                                    <Link to="/calendar">
                                        <span className="material-icons-outlined menuListIcon">calendar_month</span>
                                        <span className='menuListText d-none'>Calendar</span>
                                    </Link>
                                </li>
                                <li className={`sidebarList ${pathname === '/uploads' ? 'active' : ''}`} title='Uploads'>
                                    <Link to="/uploads">
                                        <span className="material-icons-outlined menuListIcon">file_upload</span>
                                        <span className='menuListText d-none'>Uploads</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* Footer Section */}
                    <div className='sidebarFooterSection'>
                        <div className='sidebarFooterLayout'>
                            <p className='sidebarLogout cursor-pointer' title='Logout' onClick={handleLogout}>
                            <span className="material-icons-outlined">logout</span>
                            </p>
                            <p className='fs-14 d-none pt-16'>© 2023 Smarty Pays</p>
                        </div>
                    </div>
                </div>
            </aside>        
        </>
    );
};

export default Sidebar;