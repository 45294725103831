// Importing necessary libraries and assets
import { useEffect, useState, useLayoutEffect } from 'react';
import '../../../assets/css/user/dashboard/dashboardTab.css';
import loadLogo from '../../../assets/images/global/sidebar-logo.png';
import apiClient from '../../apiClient';
import { useNavigate } from 'react-router-dom';

// Defining DashboardTab component
const DashboardTab = ({ activeTab,showPopup,setShowPopup,apiCall,setAPICall,setItem,setEditPopup,setDeletePopup,deletePopup,setUpcomingData,setDeleteId,setBudget }) => {
    // Initialize state variables
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [layoutLoad, setLayoutLoad] = useState(false);
    const currencySymbol = localStorage.getItem('currencyCode');
    const navigate = useNavigate();

    
    // Fetch data from the dashboard API
    const dashboardAPI = async () => {
        setLayoutLoad(true);
        try {
            // Fetching data from the API
            const token = localStorage.getItem('token');
            const response = await apiClient.get('/api/entries', {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
            });
            // Handling HTTP errors
            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Parsing the JSON response
            const json = response.data;

            // Extracting subscription list data
            const subscriptionListData = json.entries;

            // Handling data based on `tabActive` state
            let resultData;
            if (activeTab === 'personal' || activeTab === 'business') {
                resultData = subscriptionListData.filter(item => 
                    (activeTab === 'personal' && item.type.toLowerCase() === 'personal') ||
                    (activeTab === 'business' && item.type.toLowerCase() === 'business')
                );
                setSubscriptionList(resultData);
            }else {
                setSubscriptionList(subscriptionListData);
                setUpcomingData(json.upcoming_payments);
                setBudget(json.expected_expense);
            }

            // Updating the layout and API call states
            setLayoutLoad(false);
            setAPICall(false);

        } catch (error) {
            // Logging the error with more context
            console.error('Error fetching data from the dashboard API:', error);
            setLayoutLoad(false);
            setAPICall(false);     
            navigate('/login');
            localStorage.clear();
        }
    };
    
  // Fetch data from the API on component mount
  useEffect(() => {
    dashboardAPI();
  }, []);

  // Call dashboardAPI after the popup form is submitted
  useEffect(() => {
    if (apiCall) {
      dashboardAPI();
      setAPICall(false); // Reset the apiCall state if needed
    }
  }, [apiCall]);
    // Function to handle editing
    const handleEdit = (list) => {
        setEditPopup(true);
        if (!showPopup) {
            setShowPopup(true)
        }
        setItem(list);
    }

    // Function to handle deletion
    const handleDelete = (id) => {
        setDeleteId(id);
        if (!deletePopup) {
            setDeletePopup(true)
        }
    }

    // JSX rendering
    return (
        <div className="dashboardTabSection">
            <div className="dashboardTabLayout">
                {/* Render loading state if layoutLoad is true */}
                {layoutLoad &&
                    <div className="loadSection">
                        <div className="loadLayout">
                            <div className="loader"></div>
                            <img src={loadLogo} alt="Smarty Pays Logo" className="loadLogo" />
                        </div>
                    </div>
                }
                {/* Render subscription list if layoutLoad is false */}
                {!layoutLoad &&
                    <section>
                        {/* Check if subscriptionList is not empty */}
                        {typeof subscriptionList !=='undefined' && subscriptionList.length !== 0? (
                            <ul className="tabGridLayout">
                                {subscriptionList.map((list, index) => (
                                    <li className="tabGridList" key={index}>
                                        {/* Render subscription details */}
                                        <div className="tabLogoSection">
                                            <div className="tabLogoLayout">
                                                <h2 className="logoTitle capital">{list.name.charAt(0)}</h2>
                                            </div>
                                        </div>
                                        <div className="subDetailsSection">
                                            <div className="subDetailsLayout">
                                                <h4 className="subDetailsTitle capital" title={list.name}>{list.name}</h4>
                                                <div className="subDetailscontentFooter">
                                                    <div className="subAmoount">
                                                        {/* <span className="material-icons-round rupeeIcon fs-14">currency_rupee</span> */}
                                                        {currencySymbol === 'INR' ? 
                                                            <span className="material-icons fs-14">currency_rupee</span> :
                                                            <span className="material-icons-outlined fs-16">attach_money</span>
                                                        }
                                                        <p className="amountSub">{list.price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='iconSection'>
                                                <div className='iconLayout'>
                                                    {/* Add event handlers for edit and delete actions */}
                                                    <span className="material-icons-round fs-20 viewIcon" title='View' onClick={() => handleEdit(list)}>visibility</span>
                                                    <span className="material-icons-round fs-20 deleteIcon" title='Delete' onClick={() => handleDelete(list.id)}>delete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            // Render a message if subscriptionList is empty
                            <div className='notFound'>
                                <div className='text-center'>
                                    <span className="material-icons-outlined notFoundIcon">sentiment_dissatisfied</span>
                                    <h2 className='text-center'>Subscription Not Found</h2>
                                </div>
                            </div>
                        )}
                    </section>
                }
            </div>
        </div>
    )
}

export default DashboardTab;