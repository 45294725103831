// GoogleOneTapSignIn.js
import { useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import apiClient from '../apiClient';
import { useNavigate } from 'react-router-dom';

const GoogleOneTapSignIn = () => {
  const navigate = useNavigate();
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      googleLogin(codeResponse);
    },
    onError: (error) => console.error('Login Failed:', error)
  });

  const googleLogin = async (user) => {
    if (user) {
      try {
        const res = await apiClient.post('/api/login/google/callback', 
          { token: user.access_token }, 
          { headers: { 'Content-Type': 'application/json' }, timeout: 10000 }
        );

        if (res.status === 200) {
          handleLoginSuccess(res.data.data);
        }
      } catch (err) {
        console.error('An error occurred during Google login:', err);
      }
    }
  };

  const handleLoginSuccess = (data) => {
    localStorage.setItem('token', data.token);
    localStorage.setItem('userEmail', data.user.email);
    localStorage.setItem('userCountry', data.user.country);
    localStorage.setItem('userName',  data.user.firstname);
    localStorage.setItem('currencyCode', data.user.currency);
    localStorage.setItem('profileImg', data.user.profile_pic);
    localStorage.setItem('emailVerify', data.user.email_verified_at);
    if (data.user.country && data.user.currency) {
      navigate('/dashboard');
    } else {
      navigate('/country');
    }
  };

  useEffect(() => {
    /* global google */
    const initializeOneTap = () => {
      google.accounts.id.initialize({
        client_id: '254061407641-c3r949u3u1gon1o737824m6fjc4iqelb.apps.googleusercontent.com',
        callback: login,
        auto_select: true,
        cancel_on_tap_outside: false, 
        context: 'signin', 
      });

      google.accounts.id.prompt(notification => {
        if (notification.isNotDisplayed()) {
          console.log(notification.getNotDisplayedReason());
        } else if (notification.isSkippedMoment()) {
          console.log(notification.getSkippedReason());
        } else if (notification.isDismissedMoment()) {
          console.log(notification.getDismissedReason());
        }
      });
    };

    initializeOneTap();
  }, [login]);

  return null; // No UI elements needed for One Tap Sign-In
};

export default GoogleOneTapSignIn;