// import React, { useState, useEffect } from 'react';
import '../../../assets/css/user/userHeader/userHeader.css';
// const adList = [
//     {
//       name: 'ScrapeLocal - GMB Extractor & URL Scraper',
//       image: 'https://saasgallery.s3.amazonaws.com/ScrapeLocalGMBExtractorURLScraper/SaaSMantra-ScrapeLocalGMBExtractorURLScraper-1.png',
//       content: 'Scrape unlimited contact info from local businesses from Google My Business Listings',
//       offer: 71,
//       link: 'https://saasmantra.com/deals/Scrapelocal'
//     },
//     {
//       name: 'Powr',
//       image: 'https://saasgallery.s3.amazonaws.com/fastestvpn1/thumbnail/2023-04-12-08-33-03saasgalleryGroup%20298.png',
//       content: 'Generate More Leads, and Increase your Sales with 50+ No-code Widgets',
//       offer: 85,
//       link: 'https://saasmantra.com/deals/powr'
//     },
//     {
//       name: 'PlugSMS',
//       image: 'https://saasgallery.s3.amazonaws.com/Plugsms/SaaSMantra-Plugsms-2.png',
//       content: 'Make your mobile phone a WhatsApp and SMS gateway',
//       offer: 92,
//       link: 'https://saasmantra.com/deals/plugsms'
//     }
// ];
const UserHeader = ({sidebarActive,setSidebarActive,rightSidebarActive,setRightSidebarActive}) => {
    // const [currentAdIndex, setCurrentAdIndex] = useState(0);
    const handleSidebarActive = () => {
        if(sidebarActive){
            setSidebarActive(false);
        }else{
            setSidebarActive(true);
        }
    }
    const handleRightSidebarActive = () => {
        if(rightSidebarActive){
            setRightSidebarActive(false);
        }else{
            setRightSidebarActive(true);
        }
    }
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //       setCurrentAdIndex((prevIndex) => (prevIndex + 1) % adList.length);
    //     }, 5000);
    
    //     return () => clearInterval(interval);
    //   }, []);
    
    //   const currentAd = adList[currentAdIndex];
    return(
        <header className="userHeader p14-x">
            <div className="userHeaderSection">
                <div className="userHeaderLayout">
                    {/* Toggle Bar Section */}
                    <div className="togglebarSection">
                        <div className="togglebarLayout">
                            <div className="togglebarIcon">
                                <span className="material-icons-round cursor-pointer" onClick={handleSidebarActive}>menu_open</span>
                            </div>
                        </div>
                    </div>
                    {/* Ad section */}
                    {/* <div className="saasAdSection">
                        <a href={currentAd.link} target="_blank" rel="noopener noreferrer" className="saasAdLink">
                            <div className="saasAdLayout">
                            <div className='saasAdImage'>
                                <img src={currentAd.image} alt={currentAd.name} className='saasAdImg' />
                            </div>
                            <div className='saasAdContent'>
                                <h2>{currentAd.name}</h2>
                                <p>{currentAd.content}</p>
                                <p><span className='offerSpan'>{currentAd.offer}% OFF</span><span> in SaaS Mantra</span></p>
                            </div>
                            </div>
                        </a>
                    </div> */}
                    {/* Notifiction Bar Section */}
                    <div className="notificationbarSection">
                        <div className="notificationbarLayout">
                            <div className="menuIcon mr-10">
                                <span className="material-icons cursor-pointer" onClick={handleRightSidebarActive}>widgets</span>
                            </div>
                            <div className="notificationbarIcon">
                                <span className="material-icons cursor-pointer">notifications</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default UserHeader;