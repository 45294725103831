// VerifyEmail.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import loadLogo from '../../../assets/images/global/sidebar-logo.png';

const VerifyEmail = () => {
    let navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const url = queryParams.get('url');  
  const [layoutLoad, setLayoutLoad] = useState(true);
  
  useEffect(() => {
    const verifyToken = async () => {
      if (!url) {
        setVerificationStatus('Invalid verification URL.');
        return;
      }
      
      try {
        const response = await axios.get(url);
        setVerificationStatus(response.data.message);
        setLayoutLoad(false);
        const currency = localStorage.getItem('currencyCode');
        const country = localStorage.getItem('userCountry');
        if(currency && country){
          navigate('/dashboard');
        }else{
          navigate('/country');
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setVerificationStatus('Error verifying email.');
      }
    };

    verifyToken();
  }, []);

  return (
    <>
      {layoutLoad &&
          <div className="loadSection">
              <div className="loadLayout">
                  <div className="loader"></div>
                  <img src={loadLogo} alt="Smarty Pays Logo" className="loadLogo" />
              </div>
          </div>
      }
    </>
  );
};

export default VerifyEmail;