import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SubscriptionPopup from '../../../components/user/popup/subscriptionPopup';
import "./../../../assets/css/user/upload/uploadContent.css";
import sampleFormat from "./../../../assets/sampleFormat.csv";
import apiClient from '../../apiClient';

const UploadContent = ({ setItem, item, setEditPopup, editPopup, setShowPopup, showPopup, setRightSidebarActive }) => {
  const [fileData, setFileData] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [validate, setValidate] = useState(false);
  const token = localStorage.getItem('token');
  const [error, setError] = useState("");
  const [errorText, setErrorText] = useState(false);
  const [apiCall, setAPICall] = useState(false);
  const [calendarSub, setCalendarSub] = useState(false);
  const navigate = useNavigate();
  const [subscriptionCount, setSubscriptionCount] = useState(0);
  const expectedHeaders = ['Name', 'Amount', 'SubscriptionType', 'Type', 'Category', 'RenewalDate', 'RemindMe', 'AlertType1', 'Alert1', 'AlertType2', 'Alert2'];
// Handle file selection from input
const handleFileSelect = (event) => {
  const uploadedFile = event.target.files[0];
  if (!uploadedFile) {
    setError("No file selected");
    setValidate(true);
    setShowPreview(false);
    setErrorText(false);
    setTimeout(closePopup, 3000);
    return;
  }
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = e.target.result;
    const lines = data.split('\n').map(line => line.trim());
    const headers = lines[0].split(',').map(header => header.trim());
    
    const isValidFormat = headers.every(header => expectedHeaders.includes(header));

    if (isValidFormat) {
      setValidate(true);
      setShowPreview(true);
      setErrorText(true);
      setError("The uploaded CSV file has the expected format");
      const previewData1 = parseCSVData(lines);
      const previewArray = previewData1.filter(row => row.length > 0);
      setPreviewData(previewData1);
      setSubscriptionCount(previewArray.length-1);
      setFileData(uploadedFile);
      setTimeout(closePopup, 3000);
    } else {
      setValidate(true);
      setShowPreview(false);
      setErrorText(false);
      setError("The uploaded CSV file has an invalid format");
      setTimeout(closePopup, 3000);
    }
  };
  reader.onerror = () => {
    setError("Error reading file");
    setValidate(true);
    setShowPreview(false);
    setErrorText(false);
    setTimeout(closePopup, 3000);
  };
  reader.readAsText(uploadedFile); // Read file as text
};

// Close validation popup
const closePopup = () => {
  setValidate(false);
};


const parseCSVData = (lines) => {
  const headers = lines[0].split(',').map(header => header.trim());
  const data = lines.slice(1)
    .map(line => line.split(',').map(cell => cell.trim()))
    .filter(row => row.some(cell => cell !== '')); // Filter out rows where all cells are empty strings
  return [headers, ...data];
};

const handleBulkUpload = async (event) => {
  event.preventDefault();
  
  // Check if fileData is valid
  if (!fileData) {
    setError("No file selected for upload.");
    return;
  }

  const formData = new FormData();
  formData.append('file', fileData);
  
  try {
    const response = await apiClient.post('/api/entries/bulk-upload', formData, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    });

    if (response.status === 200) {
      // Successful response
      localStorage.setItem('subscriptionCount', subscriptionCount);
      navigate('/dashboard');
    } else {
      // Handle unexpected response status
      console.error("Unexpected response:", response);
      setError("Unexpected response from the server. Please try again.");
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        // Validation error
        console.error("Validation error:", error.response.data);
        setError("Validation error: " + error.response.data.message);
      } else {
        // Other server errors
        console.error("Server error:", error.response.data);
        setError("Server error. Please try again later.");
      }
    } else {
      // Network or client-side errors
      console.error("Network error:", error);
      setError("Network error. Please check your internet connection and try again.");
    }
  }
};



// Clear preview data
const handleClearExcel = () => {
  setShowPreview(false);
  setPreviewData(null);
};

  return (
    <div className='uploadContentSectionLayout'>
      <div className='uploadContentLayout'>
        {validate && 
          <div className="errorMessageLayout" id='errorMessageLayout'>
            <div className='errorMessageSection'>
              <p className={`fs-18 fw-500 ${errorText ? 'successMessage' : 'errorMessage'}`}>{error}</p>
              <span className="material-icons-outlined closeIcon" onClick={closePopup}>close</span>
            </div>
          </div>
        }
        <div className="uploadContentBanner p14-x">
          <div className="uploadBannerLayout">
            <h1 className="uploadBannerTitle">Upload</h1>
          </div>
        </div>
        <div className='uploadContainer pt-16'>
          <div className='uploadContainerLayout'>
            {!showPreview &&
              <div className='uploadFormSectionLayout'>
                <div className='uploadFormSection'>
                  <div className='uploadFormLayout'>
                    <div className='uploadFormContentLayout p-16'>
                      <div className='uploadAddIcon text-center'>
                        <span className="material-icons-outlined">add</span>
                      </div>
                      <div className='uploadFileDragDrop text-center'>
                        <p>Drag and Drop here</p>
                        <span className='d-block'>or</span>
                        <p>Browse</p>
                      </div>
                    </div>
                    <div className='uploadFormInput'>
                      <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileSelect}
                      />
                    </div>
                  </div>
                  <div className='uploadExcelFile'>
                    <p className='d-flex fs-12 pt-10'>Upload your edited csv file, or any other csv file that matches this template.</p>
                    <p className='fs-14 text-center pt-14'>Want to know How to do Bulk Upload</p>
                    <Link to={sampleFormat} target="_blank" download>
                      <span className="ClickToDownload">Click Here</span>
                    </Link>
                  </div>
                </div>
              </div>
            }
            {showPreview &&
              <div className='uploadPreviewSection'>
                <div className='previewBarSection'>
                  <div className='previewBarLayout'>
                    <p className='fs-16 fw-600'>Preview <span className='fs-12 fw-400 d-in-block'>(CSV format is suggested.)</span></p>
                    <div className='previewBaricon'>
                      <span className="material-icons-outlined">more_vert</span>
                    </div>
                  </div>
                </div>
                <div className='uploadPreviewLayout noScrollBar'>
                  <table className='uploadPreviewTable noScrollBar'>
                    <thead className='uploadPreviewTableHead'>
                      <tr className='uploadPreviewTableHeadRow'>
                        {expectedHeaders.map((thHead, cellIndex) => (
                          <th key={cellIndex}>{thHead}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='uploadPreviewTableBody'>
                      {/* <tr key={0} className="uploadPreviewTableBodyRow">
                        {previewData[0].map((cellValue, cellIndex) => (
                          <td key={cellIndex} className="uploadPreviewBodyData">{cellValue}</td>
                        ))}
                      </tr> */}
                      {previewData.map((row, rowIndex) => (
                        <tr key={rowIndex} className="uploadPreviewTableBodyRow">
                          {row.map((cellValue, cellIndex) => (
                            <td key={cellIndex} className="uploadPreviewBodyData">{cellValue}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className='buttonLayout'>
                  <div className='position-rel d-flex'>
                    <button className='clearBtn' onClick={handleClearExcel}>Clear</button>
                    <button className='confirmBtn' onClick={handleBulkUpload} disabled={!showPreview}>Confirm</button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      {showPopup &&
        <div className="subscriptionPopupLayout">
          <SubscriptionPopup
            setShowPopup={setShowPopup}
            setAPICall={setAPICall}
            apiCall={apiCall}
            calendarSub={calendarSub}
            setCalendarSub={setCalendarSub}
            item={item}
            editPopup={editPopup}
            setEditPopup={setEditPopup}
            setRightSidebarActive={setRightSidebarActive}
          />
        </div>
      }
    </div>
  );
};

export default UploadContent;