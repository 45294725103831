import LoginBanner from '../../../assets/images/global/login-banner.png';
const Banner = () => {
    return(
        <div className="bannerLayout">
            <div className="contentSection pb-24">
                <h2 className="contentText pb-10">Track All Your Subscriptions !</h2>
                <h2 className="contentText">Smarty way !</h2>
            </div>
            <div className="bannerImageLayout">
                <img src={LoginBanner} alt="Login Banner" className="bannerImage" />
            </div>
        </div>
    )
}

export default Banner;