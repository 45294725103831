import { useState, useEffect } from "react";
import "../../../assets/css/user/dashboard/dashboradContent.css";
import DeleteSubscription from "../../../components/user/popup/deleteSubscription";
import SubscriptionPopup from "../../../components/user/popup/subscriptionPopup";
import loadLogo from '../../../assets/images/global/sidebar-logo.png';
import Tabs from "../../../components/user/tab/tabs";
const DashboardContent = ({setUpcomingData,setBudget,setItem,item,setEditPopup,editPopup,setShowPopup,showPopup,setRightSidebarActive}) => {  
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [apiCall, setAPICall] = useState(false);  
  const [subscriptionsBar, setSubscriptionsBar] = useState(0);
  const [subscriptionUploadCount, setSubscriptionUploadCount] = useState();
  const addSubscription = () => {
    if(!showPopup){
        setItem({});
        setShowPopup(true);
    }
  }  
  const closePoup = () => {        
    setSubscriptionsBar(0);
    clearSessionStorage();
  }
  useEffect(() => {
    const count = localStorage.getItem('subscriptionCount') || '';
    setSubscriptionUploadCount(count);
    setTimeout(closePoup, 3000);
}, []);

useEffect(() => {
    if (parseInt(subscriptionUploadCount) > 0) {
        setSubscriptionsBar(1);
    }
}, [subscriptionUploadCount]);
const clearSessionStorage = () => {
    localStorage.removeItem('subscriptionCount');
    setSubscriptionUploadCount(''); // Optionally clear the state as well
};
    return(
        <div className="dashboardContentSection">
            {subscriptionsBar !== 0 && (
                <div className="errorMessageLayout" id='errorMessageLayout'>
                    <div className='errorMessageSection'>
                    <p className={`fs-18 fw-500 successMessage`}>You have upload {subscriptionUploadCount} subscriptions</p>
                    <span className="material-icons-outlined closeIcon" onClick={closePoup}>close</span>
                    </div>
                </div>     
            )}
            {apiCall && (
            <>                           
                <div className="loadSection">
                    <div className="loadLayout">                        
                        <div className="loader"></div>
                        <img src={loadLogo} alt="Smarty Pays Logo" className="loadLogo" />
                    </div>
                </div>
            </>
            )}
            <div className="dashboardContentLayout">
                {/* Dashboard Section */}
                <div className="dashboardContentBanner p14-x">
                    <div className="dashboardBannerLayout">
                        <h1 className="dashboardBannerTitle">Dashboard</h1>
                        <div className="position-rel d-flex">
                        <button className="btn-15 cursor-pointer" onClick={addSubscription}>Add Subscription</button>
                        </div>
                    </div>       
                </div>
                {/* Dashboard Tab Section */}
                <div className="dashboardSection pt-30">
                    <div className="dashboardLayout">
                        <div className="tabListLayout">
                            <section className="tabSectionLayout">
                                <Tabs showPopup={showPopup} setShowPopup={setShowPopup} setDeletePopup={setDeletePopup} deletePopup={deletePopup} apiCall={apiCall} setAPICall={setAPICall} setItem={setItem} setEditPopup={setEditPopup} setUpcomingData={setUpcomingData} setDeleteId={setDeleteId} setBudget={setBudget} />
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            {/* Subscription Popup */}
            {showPopup && 
                <div className="subscriptionPopupLayout">
                    <SubscriptionPopup setShowPopup = {setShowPopup} setAPICall={setAPICall} apiCall={apiCall} item={item} editPopup={editPopup} setEditPopup={setEditPopup}  setRightSidebarActive={setRightSidebarActive}/>
                </div>
            }
             {/* Delete Popup */}
             {deletePopup && 
                <div className="subscriptionPopupLayout">
                    <DeleteSubscription setDeletePopup={setDeletePopup} deletePopup={deletePopup} deleteId={deleteId} apiCall={apiCall} setAPICall={setAPICall} />
                </div>
            }
        </div>
    )
}

export default DashboardContent;