import '../../../assets/css/web/pages/termsConditions.css';
import Footer from '../../../components/web/footer/footer';
import Header from '../../../components/web/header/header';

const TermsConditions = () => {
    return (
        <div className='pageContainer'>
            <Header />
            <div className="termsConditionsPage">
                <section className='termsConditionsSection'>
                    <div className='termsConditionsLayout'>
                        <div className='termsConditionsHeader'>                            
                            <h1 className='fs-32 text-center pb-24'>Terms and Conditions</h1>
                        </div>
                        <div className='termsConditionsContent'>
                            <div className='contentSection'>
                                <h2 className='fs-20'>The standard Lorem Ipsum passage, used since the 1500s</h2>
                                <p className='fs-16'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                            <div className='contentSection'>
                                <h2 className='fs-20'>The standard Lorem Ipsum passage, used since the 1500s</h2>
                                <p className='fs-16'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                            <div className='contentSection'>
                                <h2 className='fs-20'>The standard Lorem Ipsum passage, used since the 1500s</h2>
                                <p className='fs-16'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                            <div className='contentSection'>
                                <h2 className='fs-20'>The standard Lorem Ipsum passage, used since the 1500s</h2>
                                <p className='fs-16'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                            <div className='contentSection'>
                                <h2 className='fs-20'>The standard Lorem Ipsum passage, used since the 1500s</h2>
                                <p className='fs-16'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default TermsConditions;