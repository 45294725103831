import '../../../assets/css/web/landingPage/landingPage.css';
import Footer from '../../../components/web/footer/footer';
import Header from '../../../components/web/header/header';
import GoogleOneTapSignIn from '../googleOneTapSignIn';
import TallyEmbed from './tallyEmbed';
const LandingPage = () => {
    return(        
        <div className='pageContainer'> 
            <Header />
            <div className="landingPage">
                <section className='iframeSection'>
                    <TallyEmbed />
                </section>
                {/* One Tab Gogle Login */}
                <section>
                    <GoogleOneTapSignIn />
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default LandingPage;