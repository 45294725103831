import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/global/smarty-pays-logo.png';
const ForgotPassword = () => {
    const [initialstate, setInitialstate] = useState({
        email: ''
    });
    const [formData, setFormData] = useState({
        email: ''
    });
    const [error, setError] = useState();
    const [passwordType, setPasswordType] = useState("password");
    const [validate, setValidate] = useState(false);
    const navigate = useNavigate();
    const handleOnChange = (e) => {
        e.preventDefault();
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        })
    }
    const closePoup = () => {        
        setValidate(false);
    }
    const handleOnSubmit = async (e) => {
        e.preventDefault();
        //handleError();
        let element = document.getElementById('fmInput');
        let elementInput = element.querySelectorAll("input:invalid");
        if(elementInput.length === 0){ 
            const response = await fetch('https://app.smartypays.com/api/forgetPassword', {
                method: "post",
                headers: {
                    // needed so express parser says the body is OK to read
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();   
            if(data.success) {
                setInitialstate();  
                setFormData(initialstate);         
                setValidate(true);   
                setError(data.message);  
                setTimeout(closePoup, 3000);
                navigate('/login');
            }
        }else{ 
            document.getElementById('fmInput').classList.add('errorInput');  
            setValidate(true);       
            setError('Filed is required');   
            setTimeout(closePoup, 3000);
        }
    }
    return(
        <div className="loginLayout w-100">
            <div className="logoSection">
                <img src={Logo} alt="Smarty Pay Logo" className='loginLogo' width="100%" height="100%" />
            </div>
            <div className="headerSection p20-y pl-20">
                <h1 className='loginTitle pb-16'>Forgot Password</h1>
                <p>Track your subscription, and save your money with Smarty Pays</p>
            </div>
            <div className="formSection pl-20">
                {validate ?     
                    <div className="errorMessageLayout" id='errorMessageLayout'>
                        <div className='errorMessageSection'>
                            <p className="errorMessage fs-18 fw-500">{error}</p>
                            <span className="material-icons-outlined closeIcon" onClick={closePoup}>close</span>
                        </div>
                    </div> : ''
                }
               <form>
                   <div className='fmInput' id='fmInput'>
                        <div className="formControl pb-16">
                            <label htmlFor="email" className='d-block pb-14'>Email *</label>
                            <input type="email" name="email" className="email" id="email" placeholder='demo@gmail.com' value={formData.email} onChange={(e) => handleOnChange(e)} required />
                        </div>
                    </div>
               </form>               
               <div className="btnSection pt-16">
                    <button type="submit" className="loginBtn fs-16" onClick={handleOnSubmit}>Send</button> 
                </div>
               <div className='p30-y text-center'>
                <p className='fs-14'>Already have an account? <span className='cursor-pointer currentLink'><Link to='/login'>Login</Link></span></p>
               </div>
            </div>
        </div>
    )
}

export default ForgotPassword;