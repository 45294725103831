import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/global/smarty-pays-logo.png';
import apiClient from '../../apiClient';
import { useGoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import loadLogo from '../../../assets/images/global/sidebar-logo.png';

const LoginForm = () => {
  const [error, setError] = useState();
  const [emailVal, setEmailVal] = useState('');
  const [passwordVal, setPasswordVal] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [validate, setValidate] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [layoutLoad, setLayoutLoad] = useState(false);

  const navigate = useNavigate();
  const [user, setUser] = useState([]);

  const googleLogin = async (user) => {
    if (user) {
      try {
        const res = await apiClient.post('/api/login/google/callback', 
          { token: user.access_token }, 
          { headers: { 'Content-Type': 'application/json' }, timeout: 10000 }
        );

        if (res.status === 200) {
          handleLoginSuccess(res.data.data);
        }
      } catch (err) {
        console.error('An error occurred during Google login:', err);
        alert('An unexpected error occurred. Please try again later.');
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      googleLogin(codeResponse);
    },
    onError: (error) => console.error('Login Failed:', error)
  });

  const handleLoginSuccess = (data) => {
    setValidate(true);
    setTimeout(() => setValidate(false), 3000);
    localStorage.setItem('token', data.token);
    localStorage.setItem('userEmail', data.user.email);
    localStorage.setItem('userCountry', data.user.country);
    localStorage.setItem('userName',  data.user.firstname);
    localStorage.setItem('currencyCode', data.user.currency);
    localStorage.setItem('profileImg', data.user.profile_pic);
    localStorage.setItem('emailVerify', data.user.email_verified_at);
    if (data.user.country && data.user.currency) {
      navigate('/dashboard');
    } else {
      navigate('/country');
    }
  };

  const togglePassword = () => {
    setPasswordType(prevType => prevType === 'password' ? 'text' : 'password');
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLayoutLoad(true);
    if (emailVal && passwordVal) {
      if (rememberMe) {
        Cookies.set('username', emailVal, { expires: 30 }); // Expires in 30 days
        // Cookies.set('password', passwordVal, { expires: 30 }); // Expires in 30 days
      } else {
        Cookies.remove('username');
        Cookies.remove('password');
      }
      try {
        const response = await apiClient.post('/api/login', {
          email: emailVal,
          password: passwordVal
        }, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        });

        handleLoginSuccess(response.data.data);
      } catch (error) {
        if (error.response) {
          setError(error.response.data.message);
          setValidate(true);
          setLayoutLoad(false);
          setTimeout(() => setValidate(false), 3000);
        }
      }
    } else {
      setValidate(true);
      setError('All fields are required');
      setTimeout(() => setValidate(false), 3000);
    }
  };

  return (
    <>
    {layoutLoad && 
      <div className="loadSection">
          <div className="loadLayout">                        
            <div className="loader"></div>
            <img src={loadLogo} alt="Smarty Pays Logo" className="loadLogo" />
          </div>
      </div>
    }            
    {!layoutLoad && 
      <div className="loginLayout w-100">
        <div className="loginLogoSection">
          <Link to="/">
            <img src={Logo} alt="Smarty Pay Logo" className='loginLogo' width="100%" height="100%" />
          </Link>
        </div>
        <div className="headerSection p20-y pl-20">
          <h1 className='loginTitle pb-16'>Login</h1>
          <p>Track your subscription, and save your money with Smarty Pays</p>
        </div>
        <div className="formSection pl-20">
          {validate && (
            <div className="errorMessageLayout" id='errorMessageLayout'>
              <div className='errorMessageSection'>
                <p className="errorMessage fs-18 fw-500">{error}</p>
                <span className="material-icons-outlined closeIcon" onClick={() => setValidate(false)}>close</span>
              </div>
            </div>
          )}
          <form onSubmit={handleOnSubmit}>
            <div className='fmInput' id='fmInput'>
              <div className="formControl pb-16">
                <label htmlFor="email" className='d-block pb-14'>Email *</label>
                <input type="email" name="email" className="email" id="email" placeholder='demo@gmail.com' value={emailVal} onChange={(e) => setEmailVal(e.target.value)} required />
              </div>                   
              <div className="formControl pb-16">
                <label htmlFor="password" className='d-block pb-14'>Password *</label>
                <input type={passwordType} name="password" className="password" id="password" placeholder='******' value={passwordVal} onChange={(e) => setPasswordVal(e.target.value)} required />
                <span className="material-icons-outlined eyeIcon" onClick={togglePassword}>
                  {passwordType === "password" ? 'visibility_off' : 'visibility'}
                </span>
              </div>
            </div>                    
            <div className="btnSection pt-16 pb-0">
              <button type="submit" className="loginBtn fs-16">Login</button> 
            </div>
          </form>
          {/*<div className='rememberSection'>
            <div className='switchControl'>
              <label className="switch" htmlFor='remember'>
                <input
                  type="checkbox"
                  id="remember"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <span className="slider round"></span>
              </label>                    
              <span className='fs-14 pl-16'>Remember for 30 days</span>
            </div>
            <div className='forgotPassword'>
              <p className='cursor-pointer currentLink'><Link to="/forgotPassword">Forgot Password</Link></p>
            </div>
          </div>*/}

          <div className='speseparatorSection'>
            <span>--------------------</span>
            <span className='textOR'>OR</span>
            <span>--------------------</span>
          </div>
          <div className='googleLoginBtn'>
            <button onClick={login}>Sign in with Google 🚀</button>
          </div>

          <div className='creatAccSection text-center pt-30'>
            <p className='fs-14'>Don't have an account yet? <span className='currentLink d-in-block'><Link to="/register">Create account</Link></span></p>    
          </div>
        </div>
      </div>
    }
    </>    
  );
}

export default LoginForm;