import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../../assets/css/user/popup/deleteSubscription.css";
import apiClient from '../../../pages/apiClient';
import loadLogo from '../../../assets/images/global/sidebar-logo.png';

const DeleteSubscription = ({ setDeletePopup, deleteId, apiCall, setAPICall }) => {
  const navigate = useNavigate();
  const [otherReason, setOtherReason] = useState(false);
  const [deleteButton, setDeleteButton] = useState(true);
  const [resData, setResData] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [otherReasonText, setOtherReasonText] = useState("");
  const [confirmText, setConfirmText] = useState(""); // State to hold confirmation text
  const [layoutLoad, setLayoutLoad] = useState(true);

  // Close the popup
  const handleClose = () => {
    setDeletePopup(false);
  };

  // Fetch reasons data from the API
  useEffect(() => {
    const fetchReasonsData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await apiClient.get('/api/common', {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });
        if (response.status === 200) {
          const reasons = response.data.reasons;
          setResData(reasons);          
          setLayoutLoad(false);
        }
      } catch (error) {
        console.error("There was an error fetching the data:", error);
      }
    };

    fetchReasonsData();
  }, []);

  // Handle selection of reasons
  const handleReason = (e, reason) => {
    if (e.target.checked) {
      setSelectedReasons([...selectedReasons, reason]); // Use spread operator to update array
    } else {
      setSelectedReasons(selectedReasons.filter((selected) => selected !== reason));
    }
  };

  // Handle input for custom reason
  const handleOtherReasonText = (e) => {
    setOtherReasonText(e.target.value);
  };

  // Handle confirmation input
  const handleConfirm = (e) => {
    setConfirmText(e.target.value); // Update confirmation text state
    setDeleteButton(e.target.value !== 'DELETE'); // Disable delete button if confirmation text is not 'DELETE'
  };

  // Handle deletion of subscription
  const handleDeleteSubs = async () => {
    if (confirmText === 'DELETE') { // Check if confirmation text matches
      const token = localStorage.getItem('token');
      try {
        const response = await apiClient.delete(`/api/entry/${deleteId}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          },
          data: {
            "reasons": [...selectedReasons, `Custom reason: ${otherReasonText}`]
          }
        });
        if (response.data.status) {
          setAPICall(!apiCall);
          handleClose();
        }
      } catch (error) {
        if (error.response) {
          console.error(error.response.data.message);
        }
      }
    } else {
      console.error("Confirmation text does not match"); // Log an error if confirmation text doesn't match
    }
  };

  return (
    <>
    {layoutLoad && 
      <div className="loadSection">
          <div className="loadLayout">                        
            <div className="loader"></div>
            <img src={loadLogo} alt="Smarty Pays Logo" className="loadLogo" />
          </div>
      </div>
    }            
    {!layoutLoad && 
    <section className='popupSection editPopupSection'>      
      <div className='popupMainContainer'>
        <div className="popupContainer">
          <form className='popupForm'>
            <div className='popupInput'>
              <div className='popupHeaderSection pb-14'>
                <div className='closePoupSection'>
                  <div className='closeIconLayout'>
                    <span className="material-icons cancelIcon" title='close' onClick={handleClose}>cancel</span>
                  </div>
                </div>
              </div>
              {resData.map((item) => (
                <div className='formControl pb-10' key={item.id}>
                  <input
                    type="checkbox"
                    id={`reason${item.id}`}
                    name="reasons"
                    value={item.reason}
                    checked={selectedReasons.includes(item.reason)}
                    onChange={(e) => handleReason(e, item.reason)}
                  />
                  <label htmlFor={`reason${item.id}`}>{item.reason}</label>
                </div>
              ))}
              <div className='formControl'>
                <input type="checkbox" id="reasonsOther" name="reasons" value="other" checked={otherReason} onChange={(e) => setOtherReason(e.target.checked)} />
                <label htmlFor='reasonsOther'>Other</label>
              </div>
              {otherReason &&
                <div className='otherReason pt-14'>
                  <div className='formControl'>
                    <textarea id="msgtext" maxLength="150" name="msgtext" rows="3" value={otherReasonText} onChange={handleOtherReasonText}></textarea>
                  </div>
                </div>
              }
              <div className='formControlconfirm pt-10'>
                <label htmlFor='deleteConfirm' className='d-block pb-10'>Type DELETE to confirm</label>
                <input type="text" id="deleteConfirm" name="confirm" onChange={handleConfirm} />
              </div>
            </div>
          </form>
          <div className='btnGroup'>
            <button className='saveBtn' onClick={handleDeleteSubs} disabled={deleteButton}>Delete</button>
          </div>
        </div>
      </div>
    </section>   
  } 
    </>
  );
}

export default DeleteSubscription;
