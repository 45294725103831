import '../../../assets/css/web/pages/contactUs.css';
import Footer from '../../../components/web/footer/footer';
import Header from '../../../components/web/header/header';
import contactImage from '../../../assets/images/global/contact-us.svg';
import { useState } from 'react';

const ContactUs = () => {
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [data, setData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [successMeg, setSuccessMeg] = useState('');
    const [validate, setValidate] = useState(false);
    const [errorText, setErrorText] = useState(false);

    const validateForm = () => {
        let tempErrors = {};
        if (!data.name) {
            tempErrors.name = 'Name is required';
        }
        if (!data.email) {
            tempErrors.email = 'Email is required';
        }
        if (!data.message) {
            tempErrors.message = 'Message is required';
        }
        setErrors(tempErrors);
        return Object.values(tempErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setValidate(true);
            setErrorText(true);
            setSuccessMeg('Our support team will contact you!');
            setTimeout(closePoup, 3000);
            setData({
                name: '',
                email: '',
                message: ''
            });
        } else {
            setValidate(true);
            setErrorText(false);
            setSuccessMeg('Please fill in the required fields');
            setTimeout(closePoup, 3000);
        }
    };

    const closePoup = () => {
        setValidate(false);
    };

    return (
        <div className='pageContainer'>
            <Header />
            <div className="contactPage">
                {validate &&
                    <div className="errorMessageLayout" id='errorMessageLayout'>
                        <div className='errorMessageSection'>
                            <p className={`fs-18 fw-500 ${errorText ? 'successMessage' : 'errorMessage'}`}>{successMeg}</p>
                            <span className="material-icons-outlined closeIcon" onClick={closePoup}>close</span>
                        </div>
                    </div>
                }
                <section className='contactSection'>
                    <div className='contactLayout'>
                        <div className='contactImageSection'>
                            <img src={contactImage} alt='Contact Us' className='contactImage' />
                        </div>
                        <div className='contactFormSection'>
                            <div className='contactFormHeaderSection'>
                                <h1 className='fs-32 text-center pb-24'>Contact Us</h1>
                                <p className='fs-16 text-center pb-10'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                            <form className='contactUsForm' onSubmit={handleSubmit}>
                                <div className='formControl'>
                                    <label>Name <span className='starValidate'>*</span></label>
                                    <input
                                        type='text'
                                        name='name'
                                        className='userName'
                                        id='userName'
                                        value={data.name}
                                        onChange={handleChange}
                                    />
                                    {errors.name && <p className='errorMessageValidate'>{errors.name}</p>}
                                </div>
                                <div className='formControl'>
                                    <label>Email <span className='starValidate'>*</span></label>
                                    <input
                                        type='email'
                                        name='email'
                                        className='userEmail'
                                        id='userEmail'
                                        value={data.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <p className='errorMessageValidate'>{errors.email}</p>}
                                </div>
                                <div className='formControl'>
                                    <label>Message <span className='starValidate'>*</span></label>
                                    <textarea
                                        name='message'
                                        className='userMessage'
                                        id='userMessage'
                                        value={data.message}
                                        onChange={handleChange}
                                    ></textarea>
                                    {errors.message && <p className='errorMessageValidate'>{errors.message}</p>}
                                </div>
                                <div className='contactBtnGroup pt-24' id='contactBtnGroup'>
                                    <button className='contactBtn' id='contactBtn'>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default ContactUs;