import React, { useState,useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import daygrid from "@fullcalendar/daygrid";
import timegrid from "@fullcalendar/timegrid";
import interaction from "@fullcalendar/interaction";
import "./../../../assets/css/user/calendar/calendarContent.css";
import SubscriptionPopup from "../../../components/user/popup/subscriptionPopup";
import "./../../../assets/css/user/popup/popup.css";
import loadLogo from '../../../assets/images/global/sidebar-logo.png';
import axios from "axios";
import apiClient from "../../apiClient";
// import '@fullcalendar/dist/fullcalendar.print.min.css';

const CalendarContent =  ({setItem,item,setEditPopup,editPopup,setShowPopup,showPopup,setRightSidebarActive,subscriptionList}) => {
  const token = localStorage.getItem('token');
    const [calendarSub, setCalendarSub] = useState(false);
    const [apiCall, setAPICall] = useState(false);
    const addSubscription = () => {
        if(!showPopup){
          setShowPopup(true);
          setCalendarSub(true);
        }
      }
      
      const [events, setEvents] = useState([]);
      const [eventPopupData, setEventPopupData] = useState([]);
      const [layoutLoad, setLayoutLoad] = useState(true);
      const [popupContent, setPopupContent] = useState(false);

      const fetchData = async () => {
        try {
            // Fetch data from the API
            const response = await apiClient.get('/api/entries', {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });

            // Handling HTTP errors
            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Parsing the JSON response
            const json = response.data;

            // Extracting subscription list data
            const subscriptionListData1 = json.entries;
            setEventPopupData(subscriptionListData1);

            // Process eventPopupData
            if (subscriptionListData1.length !== 0) {
                const eventsList = subscriptionListData1.map((event) => ({
                    title: event.name,
                    start: event.renew_date.split('-').map((part) => part.padStart(2, '0')).join('-'),
                    end: event.renew_date.split('-').map((part) => part.padStart(2, '0')).join('-'),
                    price: event.price,
                    categoryName: event.category,
                    id: event.id
                }));
                setEvents(eventsList);
                setLayoutLoad(false);
            } else {
                setLayoutLoad(false);
            }
        } catch (error) {
            // Logging the error with more context
            console.error('Error fetching data from the dashboard API:', error);
        }
    };
    useEffect(() => {  
        fetchData();
    }, []); // Empty dependency array to run the effect only once on mount
    

    const handleCloseEvent = () => {
      setPopupContent(false);
    }
  
  const handleEventClick = (info) => {
    setShowPopup(true);
   
    const eventId = info.event._def.publicId;
    const eventData = eventPopupData.filter(item => {
      return item.id && String(item.id).includes(eventId);
    });
    setItem(eventData[0]);
    setEditPopup(true);
    setCalendarSub(true);
  };

  if(apiCall){
    fetchData();
  }

  return (
    <div className="calendarMainContainer">
    {layoutLoad &&
      <div className="loadSection">
          <div className="loadLayout">                        
              <div className="loader"></div>
              <img src={loadLogo} alt="Smarty Pays Logo" className="loadLogo" />
          </div>
      </div>
    }   
    {!layoutLoad && 
      <div className="calendarSection">
        {/* calendar content section */}
        <div className="calendarContentSection">
          <div className="calendarContentLayout">
            <div className="calendarContentBanner p14-x">
              <div className="calendarBannerLayout">
                <h1 className="calendarBannerTitle">Calendar</h1>               
                <div className="position-rel d-flex">
                  <button className="btn-15 cursor-pointer" onClick={addSubscription}>Add Subscription</button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        {/* calendar plugin */}
        <div className="calendarBodySection noScrollBar p20-y">
            <div className="calendarBodyLayout">
              <FullCalendar
                plugins={[daygrid, timegrid, interaction]}
                initialView="dayGridMonth"
                events={events}
                eventClick={handleEventClick}
                height={600}           
                />          
            </div>
        </div>
  



      </div>
    }
      {showPopup && 
        <div className="subscriptionPopupLayout">
          <SubscriptionPopup setShowPopup = {setShowPopup} setAPICall={setAPICall} apiCall={apiCall} calendarSub = {calendarSub} setCalendarSub={setCalendarSub} item={item} editPopup={editPopup} setEditPopup={setEditPopup} setRightSidebarActive = {setRightSidebarActive} />
        </div>
      }
      {/* popoup content  */}
      {/* {popupContent && (
        <div className="eventPopupsection">
          <div className="eventPopupLayout">
            <div className="closeEventPopup">
              <span className="material-icons-outlined" onClick={handleCloseEvent}>close</span>
            </div>
            {eventPopupData.map((eventData,index) => (
              <div className="eventPopup" key={index}>
                <h3 className="text-center">{eventData.title}</h3>
                <div className="d-flex pt-10 eventList">
                  <span className="material-icons-outlined">calendar_month</span>
                  <span className="eventSpan">{eventData.end}</span>
                </div>
                <div className="d-flex pt-10 eventList">
                  <span className="material-icons-outlined">paid</span>
                  <span className="eventSpan">{eventData.price}</span>
                </div>
                <div className="d-flex pt-10 eventList">
                  <span className="material-icons-outlined">category</span>
                  <span className="eventSpan">{eventData.categoryName.categoryName}</span>
                </div>
              </div>
            )
            )}
          </div>
        </div>
      )} */}
    </div>
  );
};

export default CalendarContent;
