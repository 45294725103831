import { useEffect } from "react";

const TallyEmbed = () => {
  useEffect(() => {
    const loadTallyScript = () => {
      const script = document.createElement("script");
      script.src = "https://tally.so/widgets/embed.js";
      script.async = true;
      script.onload = () => {
        // Check again if Tally is available after the script loads
        if (typeof window.Tally !== "undefined") {
          window.Tally.loadEmbeds();
        }
      };
      script.onerror = () => {
        console.error("Failed to load Tally script.");
      };
      document.body.appendChild(script);
    };

    // Check if Tally script has already been loaded
    if (typeof window.Tally !== "undefined") {
      window.Tally.loadEmbeds();
    } else if (!document.querySelector(`script[src="https://tally.so/widgets/embed.js"]`)) {
      // Load the script only if it's not already in the document
      loadTallyScript();
    }
  }, []);

  return (
    <iframe
      data-tally-src="https://tally.so/embed/mJLWvo?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
      width="100%"
      height="100%"
      title="New Affiliate Program"
    />
  );
};

export default TallyEmbed;